import UsersIcon from '@material-ui/icons/AppsRounded';

import { UsersEdit } from './edit';
import { UsersList } from './list';
import { UsersShow } from './show';
import { UsersCreate } from './create';

export default {
    name: 'users',
    options: { label: 'Main', menuParent: '_users' },
    icon: UsersIcon,
    list: UsersList,
    edit: UsersEdit,
    show: UsersShow,
    create: UsersCreate
};