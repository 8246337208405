import OrganizationsIcon from '@material-ui/icons/AppsRounded';

import { OrganizationsEdit } from './edit';
import { OrganizationsList } from './list';
import { OrganizationsShow } from './show';
import { OrganizationsCreate } from './create';

export default {
    name: 'organizations',
    options: { label: 'Main', menuParent: '_organizations' },
    icon: OrganizationsIcon,
    list: OrganizationsList,
    show: OrganizationsShow,
    edit: OrganizationsEdit,
    create: OrganizationsCreate
};