import StoriesIcon from '@mui/icons-material/ChromeReaderMode';

import { StoriesEdit } from './edit';
import { StoriesList } from './list';
import { StoriesShow } from './show';
import { StoriesCreate } from './create';

export default {
    name: 'stories',
    options: { label: 'Stories', menuParent: '_events' },
    icon: StoriesIcon,
    list: StoriesList,
    show: StoriesShow,
    edit: StoriesEdit,
    create: StoriesCreate
};