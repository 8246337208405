import React from 'react';
import {
	List, 
	TextField,
	ChipField
} from 'react-admin';
import PermissionsFilters from './filter';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const PermissionsList = (props) => (
	<List
		title='Settings'
		filters={<PermissionsFilters />}
		pagination={<Paginator />}
		perPage={15}
		sort={{ field: 'id', order: 'DESC' }}
		{...props}
		empty={<EmptyPage />}
		actions={<ListActions listExporterType='Settings' hideCreateButton />}
	>
		<DataGridList show={false} isEditable={record => false} isDeletable={record => false}>
			<TextField source='name' label='Name' />
			<TextField source='resource' label='Resource' />
			<ChipField source='action' label='Action' />
		</DataGridList>
	</List>
);
