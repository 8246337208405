import React from 'react';
import { useRecordContext, Labeled } from 'react-admin';

export const ColorField = ({ source, label }) => {
    const record = useRecordContext(); 
    const color = record && record[source] ? record[source] : '#000000'; 

    const colorBox = (
        <div style={{ display: 'inline-block', width: '40px', height: '20px', backgroundColor: color, border: '1px solid #ccc' }}></div>
    );

    return label ? (
        <Labeled label={label}>
            {colorBox}
        </Labeled>
    ) : (
        colorBox
    );
};
