import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const ServiceFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='ic_name' label='Name' />
		<ReferenceInput
			label='Service Category'
			source='ic_category_id'
			reference='service_categories'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'name', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceInput>
	</FilterContainer>
);

export default ServiceFilters;