import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { 
	Show, 
	TabbedShowLayout, 
	Tab,
	TextField, 
	ReferenceField,
	ChipField,
	ReferenceArrayField,
	SingleFieldList,
	ArrayField,
	Datagrid,
	FileField,
	DateField,
	RichTextField,
	FunctionField,
	BooleanField,
	UrlField
} from 'react-admin';

export const EventsShow = (props) => (
	<Show title={<ViewTitle source='title' />} {...props}>
		<TabbedShowLayout>
			<Tab label='General Details'>
				<TextField source='id' label='ID' />
				<ChipField source='type' label='Type' />
				<TextField source='title' label='Title' />
				<RichTextField source='description' label='Description' />
				<ReferenceField label='Story' source='story_id' reference='stories'>
					<TextField source='name' label='Name' />
				</ReferenceField>
				<ReferenceField label='State' source='state_id' reference='states'>
					<TextField source='name' label='Name' />
				</ReferenceField>
				<TextField source='city' label='City' />
				<TextField source='institution' label='Institution' />
				<ReferenceArrayField source='domains_ids' reference='domains' label='Domains'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField source='categories_ids' reference='categories' label='Categories'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField source='services_ids' reference='services' label='Services'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
				<ReferenceArrayField source='fields_of_operation_ids' reference='fields_of_operation' label='Fields of Operation'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
				<DateFieldShow source='date' label='Date' />
				<TextField source='severity' label='Severity' />
				<BooleanField source='is_global' label='Global?' variant='standard' />
				<BooleanField source='is_important' label='Important?' variant='standard' />
				<DateFieldShow source='created_at' label='Created At' />
				<DateFieldShow source='updated_at' label='Updated At' />
			</Tab>
		
			<Tab label='Media'>
				<TextField source='data_source' label='Data Source' />
				<TextField source='media_source' label='Media Source' />

				<ArrayField label='External Links' source='external_links' fullWidth>
					<Datagrid>
						<UrlField source='url' label='URL' />
						<TextField source='display_name' label='Display Name' />
					</Datagrid>
				</ArrayField>

				<ArrayField label='Attachments' source='attachments' fullWidth>
					<Datagrid>
						<ReferenceField label='ID' source='id' reference='attachments'>
							<TextField source='id' />
						</ReferenceField>	
						<FileField source='file.url' title='file.key' label='File' />
						<ChipField source='type' label='Type' />
						<DateField source='created_at' label='Created At'  />
						<DateField source='updated_at' label='Updated At'  />
					</Datagrid>
				</ArrayField>
			</Tab>

			<Tab label='Taggings'>
				<ReferenceArrayField source='tags_ids' reference='tags' label=''>
					<SingleFieldList>
						<FunctionField render={(record) => {
							const name = record.parent ? `${record.parent.name} -> ${record.name}` : record.name
							return <ChipField record={{ name }} source="name" />
						}} />
					</SingleFieldList>
				</ReferenceArrayField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);