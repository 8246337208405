// components/CustomTextInputWithTooltip.js
import React from 'react';
import { TextInput } from 'react-admin';
import { Tooltip, InputAdornment } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const CustomTextInputWithTooltip = ({ source, label, disabled, ...rest }) => {
  const tooltipMessage =
    "This name is not editable as it is currently utilized by the backend system.";

  return (
    <TextInput
      source={source}
      label={label}
      disabled={disabled}
      fullWidth
      InputProps={{
        endAdornment: disabled ? (
          <InputAdornment position="end">
            <Tooltip title={tooltipMessage} arrow>
              <WarningAmberIcon color="warning" />
            </Tooltip>
          </InputAdornment>
        ) : null,
      }}
      {...rest}
    />
  );
};

export default CustomTextInputWithTooltip;
