import React from 'react';
import {
	Show,
	SimpleShowLayout,
	TextField,
	ChipField,
	FunctionField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const UsersShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
    <FunctionField
				label='Name'
				source='name'
				sortBy='first_name'
				render={(record) =>  {
					const { organizationName } = record.kyc_details;

					return (
						<>
							<div>{record.first_name} {record.last_name}</div>
							{organizationName ? <div style={{fontSize: '10px'}}>({organizationName})</div> : ''}
						</>
					);
				}}
			/>
			<TextField source='email' label='Email' />
			<ChipField source='status' label='Status' variant="outlined" />
			<ReferenceField label='Type' source='type_id' reference='user_types'>
				<TextField source='name' />
			</ReferenceField>
      <ReferenceArrayField source='organizations_ids' reference='organizations' label='Editable Organizations'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceArrayField>
		</SimpleShowLayout>
	</Show>
);
