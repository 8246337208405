import React from 'react';
import AccountFilters from './filter';
import {
  List,
  TextField,
  FunctionField
} from 'react-admin';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const AccountsList = (props) => (
	<List
		title='Accounts'
		filters={<AccountFilters />}
		sort={{ field: 'id', order: 'DESC' }}
		pagination={<Paginator />}
		perPage={25}
		{...props}
		empty={<EmptyPage create/>}
		actions={<ListActions listExporterType='AdminUsers' />}
	>
		<DataGridList>
			<FunctionField
				label='Name'
				sortBy='first_name'
				render={(record) => `${record.first_name} ${record.last_name}`}
			/>
			<TextField source='email' label='Email' />
		</DataGridList>
	</List>
);