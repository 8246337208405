import React from 'react';
import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField,
	ChipField
} from 'react-admin';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const SettingsShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='key' label='Key' />
			<TextField source='value' label='Value' />
			<ChipField source='data_type' label='Data Type' />
			<TextField source='description' label='Description' />
			<BooleanField source='is_client_accessible' label='Client Accessible?' />
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);
