import React from 'react';
import {
	BooleanInput,
  	TextInput
} from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const SettingsFilters = (props) => (
  	<FilterContainer {...props} variant='standard' margin='normal'>
		<TextInput label='Key' source='isw_key' />
		<DebouncedTextField label='Value' source='ic_value' />
		<BooleanInput source='is_client_accessible' label='Client Accessible?' />
  	</FilterContainer>
);

export default SettingsFilters;
