import React from 'react';
import {
	BooleanInput
} from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const RolesFilter = (props) => (
  	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='isw_name' label='Name' />
		<BooleanInput source='is_default' label='Default?' />
  	</FilterContainer>
);

export default RolesFilter;
