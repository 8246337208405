import moment from "moment";

export const HelperListExporter = async (data, fetchRelatedRecords, fileName, cb) => {
	const exportableRecords = data.map((record) => {
        cb?.(record,fetchRelatedRecords);
		const { created_at, updated_at, ...exportableFields } = record;

		exportableFields.created_at =
			created_at && moment(created_at)._d.toLocaleDateString("en-US");
		exportableFields.updated_at =
			updated_at && moment(updated_at)._d.toLocaleDateString("en-US");

		return exportableFields;
	});

	return {
		data: exportableRecords,
		headers: [],
		fileName
	};
};
