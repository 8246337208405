import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { Create, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

export const ServicesCreate = (props) => {
	return (
		<>
			<Create {...props} title='Create Service'>
				<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
						<TextInput source='name' validate={required()} fullWidth />
						<ReferenceInput
							label='Service Category'
							source='category_id'
							reference='service_categories'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'name', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteInput
								resettable
								matchSuggestion={(filterValue, suggestion) => true}
								optionText={(choice) =>
									choice && choice.id ? `${choice.name}` : 'None'
								}
							/>
						</ReferenceInput>
						<BooleanInput source='is_global' label='Global?' fullWidth />
				</SimpleForm>
			</Create>
		</>
	);
}