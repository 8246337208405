import React from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Popover,
  } from "@material-ui/core";
import {ExpandLess,ExpandMore,ViewList as DefaultIcon} from '@material-ui/icons';

const ParentMenu = ({ open, onMenuClick, parent, primaryIcon,onParentClick,isParentOpen,closeParent,...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const ParentClickHandler = (e) => {
    if (!open) {
        handleClick(e)
    } else {
        onParentClick(e)
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopOver = Boolean(anchorEl);
  return (
    <>
      <ListItem button onClick={ParentClickHandler}>
        <ListItemIcon>{parent?.icon ? <parent.icon /> : <DefaultIcon />}</ListItemIcon>
        <ListItemText primary={parent?.options?.label} />
        {isParentOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {
          open ? (
            <Collapse in={isParentOpen} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>
                     {props.children}
                 </List>
            </Collapse>
          ) : (
            <Popover
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
               <List component="div" disablePadding>
                   {props.children}
               </List>
          </Popover>
          )
      }
    </>
  );
};

export default ParentMenu;
