import React from 'react';
import { DateField } from 'components/Fields';
import { ShowButton } from 'components/Buttons';
import { Datagrid, TextField, EditButton, DeleteButton, FunctionField } from 'react-admin';

const defaultButtonToggle = (fn, record) => fn ? fn(record) : true;

const DataGridList = ({ 
	disableDates, 
	expandable, 
	show, 
	isEditable, 
	isDeletable, 
	generatePanelExpander,
	customButtons,
	children,
	resource
}) => {
	return (
		<Datagrid id={`${resource}-table`} expand={expandable ? generatePanelExpander() : null}>
			<TextField source="id" label="ID" />
			{ children }
			{ disableDates ? null : <DateField label="Created At" source="created_at" /> }
			{ disableDates ? null : <DateField label="Updated At" source="updated_at" /> }
			{ (typeof show === 'undefined' || show) && <ShowButton /> }
			{ <FunctionField render={(record) => defaultButtonToggle(isEditable, record) ? <EditButton record={record} /> : null} /> }
			{ <FunctionField render={(record) => defaultButtonToggle(isDeletable, record) ? <DeleteButton record={record} /> : null} /> }
			{ customButtons && customButtons.map((CustomButton, index) => (<CustomButton key={index} />)) }
		</Datagrid>
	);
};

export default DataGridList;