import { UploadAndSave } from "./UploadAndSave";
import { Toolbar, DeleteButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	toolbar: {
		justifyContent: "space-between"
	}
});

export const CreateToolbar = (props) => {
  	const classes = useStyles();
  	return (
		<Toolbar {...props} className={classes.toolbar}>
			<UploadAndSave 
				label="create" 
				redirect={props.redirectTo || 'show'} 
				beforeSaveCallback={props.beforeSaveCallback || (() => {})}
				submitOnEnter  
			/>
			{!props.hidedelete && <DeleteButton />}
		</Toolbar>
  	);
};
