import OrganizationsIcon from '@material-ui/icons/GroupRounded';

import { Resource } from 'react-admin';

import leadsViews from './Lead';
import fundersViews from './Funder';
import organizationsViews from './Organization';

const OrganizationRoutes = [
  <Resource
    name='_organizations'
    icon={OrganizationsIcon}
    options={{ label: 'Organizations', isMenuParent: true }}
  />,
  <Resource {...organizationsViews} />,
  <Resource {...leadsViews} />,
  <Resource {...fundersViews} />
];
export default OrganizationRoutes;
