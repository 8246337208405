import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { 
	Edit, 
	SimpleForm, 
	NumberInput, 
	ReferenceInput,
	AutocompleteInput,
	required 
} from 'react-admin';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import _ from 'lodash';

export const TrendsEdit = (props) => {
	return (
		<>
			<Edit title={<EditTitle source='name' />} {...props}>
				<SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
					<ReferenceInput
						label='State'
						source='state_id'
						reference='states'
						perPage={1000}
						filterToQuery={(searchText) => ({
							q: searchText,
							_q_op: 'isw',
							_autocomplete: true
						})}
						sort={{ field: 'iso', order: 'ASC' }}
						fullWidth
					>
						<AutocompleteInput
							resettable
							matchSuggestion={(filterValue, suggestion) => true}
							optionText={(choice) =>
								choice && choice.id ? `${choice.name} (${choice.iso})` : 'None'
							}
						/>
					</ReferenceInput>
					<ReferenceInput
						label='Domain'
						source='domain_id'
						reference='domains'
						perPage={1000}
						filterToQuery={(searchText) => ({
							q: searchText,
							_q_op: 'isw',
							_autocomplete: true
						})}
						sort={{ field: 'name', order: 'ASC' }}
						fullWidth
					>
						<AutocompleteInput
							resettable
							matchSuggestion={(filterValue, suggestion) => true}
							optionText='name'
						/>
					</ReferenceInput>
					<ReferenceInput
						label='Category'
						source='category_id'
						reference='categories'
						perPage={1000}
						filterToQuery={(searchText) => ({
							q: searchText,
							_q_op: 'isw',
							_autocomplete: true
						})}
						sort={{ field: 'name', order: 'ASC' }}
						fullWidth
					>
						<AutocompleteInput
							resettable
							matchSuggestion={(filterValue, suggestion) => true}
							optionText='name'
						/>
					</ReferenceInput>
            		<NumberInput source='value' validate={required()} fullWidth />
				</SimpleForm>
			</Edit>
		</>
	);
}
