import React from 'react';
import { List, TextField, ReferenceField } from 'react-admin';

import TrendFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const TrendsList = (props) => (
	<List
		filters={<TrendFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions />}
	>
		<DataGridList>
			<ReferenceField label='State' source='state_id' reference='states'>
				<TextField source='iso' />
			</ReferenceField>
			<ReferenceField label='Domain' source='domain_id' reference='domains'>
				<TextField source='name' />
			</ReferenceField>
			<ReferenceField label='Category' source='category_id' reference='categories'>
				<TextField source='name' />
			</ReferenceField>
			<TextField source='value' label='Value' />
		</DataGridList>
	</List>
);