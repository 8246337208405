import ServicesIcon from '@mui/icons-material/Class';

import { Resource } from 'react-admin';
import servicesViews from './Service';
import serviceCategoriesViews from './ServiceCategory';
import majorServiceCategoriesViews from './MajorServiceCategory'

const ServiceRoutes = [
  <Resource
    name='_services'
    icon={ServicesIcon}
    options={{ label: 'Services', isMenuParent: true }}
  />,
  <Resource {...servicesViews} />,
  <Resource {...serviceCategoriesViews} />,
  <Resource {...majorServiceCategoriesViews} />
];

export default ServiceRoutes;
