import ServiceCategoriesIcon from '@mui/icons-material/Dns';

import { ServiceCategoriesEdit } from './edit';
import { ServiceCategoriesList } from './list';
import { ServiceCategoriesShow } from './show';
import { ServiceCategoriesCreate } from './create';

export default {
    name: 'service_categories',
    options: { label: 'Service Categories', menuParent: '_services' },
    icon: ServiceCategoriesIcon,
    list: ServiceCategoriesList,
    show: ServiceCategoriesShow,
    edit: ServiceCategoriesEdit,
    create: ServiceCategoriesCreate
};