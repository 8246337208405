import React from 'react';
import { 
	SelectInput,
	ReferenceArrayInput,
	AutocompleteArrayInput,
} from 'react-admin';
import FilterContainer from 'components/containers/Filter';
import { DebouncedTextField } from 'components/Fields';


const LeadFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='ic_name' label='Name' />
		<DebouncedTextField source='ic_creator_name' label='Registered By' />
		<SelectInput
			source='size'
			label='Size'
			choices={[
				{ id: 'GrassrootsOrStartup', name: 'Grassroots / Startup' },
				{ id: 'MediumNationalOrRegionalFocus', name: 'Medium - National / Regional Focus' },
				{ id: 'MajorInternationalAndMajorFunding', name: 'Major - International / Major Funding' },
			]}
		/>		
		<ReferenceArrayInput
			label='Tags'
			source='$tags->id$'
			reference='tags'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'id', order: 'ASC' }}
		>
			<AutocompleteArrayInput
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceArrayInput>
		<DebouncedTextField source='ic_agenda' label='Agenda' />
		<DebouncedTextField source='ic_additional_target_audiences' label='Additional Target Audiences' />
	</FilterContainer>
);

export default LeadFilters;