import FieldsOfOperationIcon from '@mui/icons-material/Public';

import { Resource } from "react-admin";
import fieldsOfOperationViews from './FieldOfOperation';

const FieldsOfOperationRoutes = [
  <Resource
    name="_fields_of_operation"
    icon={FieldsOfOperationIcon}
    options={{ label: "Fields Of Operation", isMenuParent: true }}
  />,
  <Resource {...fieldsOfOperationViews} />,
];
export default FieldsOfOperationRoutes;
