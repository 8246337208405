import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import AddSourceButton from "components/Buttons/AddSource";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExportButtonIcon from "@material-ui/icons/GetApp";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import ExcelIcon from "@material-ui/icons/GridOn";
import CSVIcon from "@material-ui/icons/Subject";

import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import jsonExport from "jsonexport/dist";

import {
  Pagination,
  ExportButton,
  useListContext,
  downloadCSV,
} from "react-admin";

const pdfHtmlResouceCustomize = (resource) => {
  return resource.replaceAll("/", "_");
};

const useStyles = makeStyles(() => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    overflowY: "hidden",
    marginTop: "13px",
  },
}));

export const Paginator = (props) => {
  const classes = useStyles();
  const {
    className,
    maxResults,
    showExportButton,
    type,
    data,
    originalResource,
    subList,
    userID,
    ...rest // eslint-disable-line
  } = props;
  const { currentSort, resource, total } = useListContext();
  const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState(null);
  const modifiedData = data && Object.values(data);

  let dynamicExportFileType;
  let finalExporterData;

  let finelExporter = async (records, fetchRelatedRecords) => {
    switch (type) {
      default:
        break;

      }

    switch (dynamicExportFileType) {
      case "EXCEL":
        let binaryWS = XLSX.utils.json_to_sheet(finalExporterData.data);
        let webBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          webBook,
          binaryWS,
          finalExporterData.fileName
        );
        XLSX.writeFile(webBook, `${finalExporterData.fileName}.xlsx`);
        break;

      case "CSV":
        jsonExport(
          finalExporterData.data,
          {
            headers: finalExporterData.headers,
          },
          (err, csv) => {
            downloadCSV(csv, finalExporterData.fileName);
          }
        );
        break;

      case "PDF":
        var doc = new jsPDF("l", "pt");
        doc.autoTable({
          theme: "grid",
          // html: `#${originalResource}-${subList}-sub-table`,
          html: `#${pdfHtmlResouceCustomize(resource)}-table`,
          styles: {
            minCellWidth: 40,
          },
        });
        doc.save(`${finalExporterData.fileName}.pdf`);
        break;
      default:
        break;
    }
  };

  const handleClick = (event) => {
    setExportButtonAnchorEl(event.currentTarget);
  };

  const handleClose = (exportFileType) => {
    setExportButtonAnchorEl(null);

    switch (exportFileType) {
      case "EXCEL":
        dynamicExportFileType = "EXCEL";
        break;

      case "CSV":
        dynamicExportFileType = "CSV";
        break;

      case "PDF":
        dynamicExportFileType = "PDF";
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.paginationContainer}>
      {showExportButton && total !== 0 && (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            size="small"
            onClick={handleClick}
            startIcon={<ExportButtonIcon />} // eslint-disable-line
          >
            EXPORT
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={exportButtonAnchorEl}
            keepMounted
            open={Boolean(exportButtonAnchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleClose("EXCEL")}
              aria-controls="simple-menu"
              aria-haspopup="true"
              size="small"
              startIcon={<ExportButtonIcon />} // eslint-disable-line
            >
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                maxResults={total}
                exporter={finelExporter}
                label="EXCEL"
                icon={<ExcelIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>

            <MenuItem onClick={() => handleClose("CSV")}>
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                maxResults={total}
                exporter={finelExporter}
                label="CSV"
                icon={<CSVIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleClose("PDF")}>
              <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                maxResults={total}
                exporter={finelExporter}
                label="PDF"
                icon={<PDFIcon />}
                style={{ backgroundColor: "transparent" }}
              />
            </MenuItem>
          </Menu>
        </>
      )}

      {type === "UserSources" && <AddSourceButton userID={userID} />}

      <Pagination
        style={{ marginLeft: "auto", overflowY: "hidden" }}
        rowsPerPageOptions={[15, 25, 50, 100, 500]}
        {...props}
      />
    </div>
  );
};
