import React from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	SelectInput
} from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const SettingsCreate = (props) => (
	<Create {...props} title='Create Setting'>
		<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
			<TextInput source='key' validate={required()} fullWidth />
			<TextInput source='value' validate={required()} fullWidth />
			<SelectInput
				validate={required()}
				source='data_type'
				label='Data Type'
				choices={[
					{ id: 'string', name: 'String' },
					{ id: 'integer', name: 'Integer' },
					{ id: 'decimal', name: 'Decimal' },
					{ id: 'boolean', name: 'Boolean' },
					{ id: 'csv', name: 'CSV' }
				]}
				fullWidth
			/>
			<TextInput source='description' fullWidth />
			<BooleanInput source='is_client_accessible' label='Client Accessible?' fullWidth />
		</SimpleForm>
	</Create>
);