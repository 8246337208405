import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, Collapse, IconButton, List, ListItem, Divider, Typography } from '@mui/material';
import { ChipField, useRecordContext, ReferenceField, TextField, Labeled } from 'react-admin';

export const FieldsOfOperationServicesField = ({ source, label }) => {
    const record = useRecordContext();
    const [expanded, setExpanded] = useState({});

    if (!record || !record[source]) {
        return null;
    }

    const handleExpandClick = (id) => {
        setExpanded((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const renderFieldOfOperation = (fieldRecord) => {
        const { field_of_operation, services } = fieldRecord;
        const colorStyle = {
            backgroundColor: field_of_operation.color || '#ccc',
            color: '#fff',
            marginRight: '8px',
        };

        return (
            <Card key={field_of_operation.id} style={{ marginBottom: '16px', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                <CardContent
                    onClick={() => handleExpandClick(field_of_operation.id)}
                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Typography variant='h6' component='div' style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                        <ChipField
                            record={{ name: field_of_operation.name }}
                            source='name'
                            style={colorStyle}
                        />
                    </Typography>
                    <IconButton
                        onClick={() => handleExpandClick(field_of_operation.id)}
                        aria-expanded={expanded[field_of_operation.id]}
                        aria-label='show more'
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardContent>
                <Collapse in={expanded[field_of_operation.id]} timeout='auto' unmountOnExit>
                    <CardContent>
                        <Typography
                            variant='h6'
                            style={{
                                marginBottom: '16px',
                                fontWeight: '600',
                                color: '#3f51b5',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            Services
                        </Typography>
                        <Divider style={{ marginBottom: '16px' }} />
                        
                        <List>
                            {services.map(service => (
                                <ListItem key={service.id} style={{ paddingLeft: '0' }}>
                                    <ReferenceField
                                        label='Service'
                                        source='id'
                                        reference='services'
                                        record={service}
                                    >
                                        <TextField source='name' />
                                    </ReferenceField>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Collapse>
            </Card>
        );
    };

    return (
        label ? 
        <Labeled label={label} fullWidth>
            <div>
                {record[source].filter(field => field?.field_of_operation?.id).map(field => renderFieldOfOperation(field))}
            </div>
        </Labeled> 
        :
        <div>
          {record[source].filter(field => field?.field_of_operation?.id).map(field => renderFieldOfOperation(field))}
        </div>

    );
};