import React, { useState, useEffect } from 'react';
import { useInput, Labeled, required } from 'react-admin';

// Validator function to check if the hex color is valid
const validateHex = (value) => {
  // If the field is empty, don't validate hex format (required validator will catch it)
  if (!value) return undefined;

  const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
  if (!hexRegex.test(value)) {
    return 'Invalid hex color';
  }
  return undefined; // Valid case, no error message
};

export const ColorInput = ({ source, label, validate = [] }) => {
  const {
      input: { onChange, value, ...inputProps },
      meta: { touched, error },
      isRequired
  } = useInput({ source, validate: [...(Array.isArray(validate) ? validate : [validate]), validateHex] }); 

  const [color, setColor] = useState(value || '');

  // Sync state with value from react-admin
  useEffect(() => {
    if (value !== color) {
      setColor(value || '');
    }
  }, [value]);

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setColor(newColor);
    onChange(newColor); // Sync value with react-admin form
  };

  const handleHexChange = (event) => {
    const hex = event.target.value;
    setColor(hex);
    onChange(hex); // Sync value with react-admin form (validation will catch invalid hex)
  };

  const inputField = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      {/* Color picker */}
      <input
          type="color"
          value={color || '#000000'} // Fallback for color picker
          onChange={handleColorChange}
          {...inputProps}
          style={{
            width: '50px',
            height: '2.5em',
            border: touched && error ? '2px solid red' : '1px solid #ccc', // Red border on error
            cursor: 'pointer'
          }}
      />
      {/* Hex input */}
      <input
          type="text"
          value={color}
          onChange={handleHexChange}
          style={{
            width: '100px',
            height: '2.5em',
            border: touched && error ? '2px solid red' : '1px solid #ccc', // Red border on error
            paddingLeft: '5px'
          }}
      />
    </div>
  );

  return (
    <div style={{ marginTop: '1em', width: '100%' }}>
      {label ? (
        <Labeled label={label} source={source} isRequired={isRequired}>
          {inputField}
        </Labeled>
      ) : (
        inputField
      )}
      {/* Show error if the field is touched and invalid */}
      {touched && error && (
        <span style={{ color: 'red', fontSize: '12px', marginTop: '5px', display: 'block' }}>
          {error === 'ra.validation.required' ? 'Required' : error}
        </span>
      )}
    </div>
  );
};