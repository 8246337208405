import React from 'react';
import IncidentIcon from '@material-ui/icons/LocationSearchingRounded';
import NewsIcon from '@material-ui/icons/DescriptionOutlined';
import InsightIcon from '@material-ui/icons/EmojiObjectsOutlined';
import { 
	List, 
	TextField, 
	ChipField,
	FunctionField 
} from 'react-admin';

import EventFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const EventsList = (props) => (
	<List
		filters={<EventFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions />}
	>
		<DataGridList show>
			<TextField source='title' label='Title' />
			<FunctionField
				label='Type'
				style={{width: '500px'}}
				render={(record) => 
					<ChipField 
						record={record} 
						source='type' 
						variant='outlined'
						icon={
							record.type === 'Incident' ?
								<IncidentIcon /> :
								(
									record.type === 'NewsItem' ?
										<NewsIcon /> :
										<InsightIcon />
								)
						}
					/>
				}
			/>
			<FunctionField
				label='Status'
				render={(record) => 
					<ChipField 
						record={record} 
						source='status' 
						variant='outlined'
						style={{
							color: record.status === 'draft' ? '#B38819' : '#149D00',
							borderColor: record.status === 'draft' ? '#B38819' : '#149D00',
							backgroundColor: record.status === 'draft' ? '#FDF5D9' : '#DCF2D9'
						}}
					/>
				}
			/>
		</DataGridList>
	</List>
);