import React from 'react';
import { List, TextField } from 'react-admin';

import EmptyPage from 'components/EmptyPage';
import FieldsOfOperationFilters from './filter';
import DataGridList from 'components/containers/List';
import { ColorField } from 'components/Fields/ColorField';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const FieldsOfOperationList = (props) => (
	<List
		filters={<FieldsOfOperationFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions listExporterType='States' />}
	>
		<DataGridList isDeletable={record => false}>
			<TextField source='name' label='Name' />
			<ColorField source='color' />
		</DataGridList>
	</List>
);