import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin'; 
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress } from '@mui/material';

const AddFunderModal = ({ open, onClose, onFunderCreated  }) => {
    const [funderName, setFunderName] = useState(''); // State to store funder name
    const [loading, setLoading] = useState(false); // Loading state for the button
    const dataProvider = useDataProvider(); // Hook to interact with dataProvider
    const notify = useNotify(); // Hook for showing notifications

    // Function to handle creating a new funder
    const handleCreateFunder = async () => {
        if (!funderName) {
            notify('Funder name is required', { type: 'warning' });
            return;
        }

        setLoading(true);
        try {
            // Create new funder via dataProvider
            await dataProvider.create('funders', { data: { name: funderName } });
            notify('Funder created successfully', { type: 'info' });
            setFunderName(''); // Clear input field
            onFunderCreated();
            onClose(); // Close the modal

        } catch (error) {
            notify('Error creating funder', { type: 'error' });
        } finally {
            setLoading(false); // Stop loading spinner
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add New Funder</DialogTitle>
            <DialogContent>
                <TextField
                    label="Funder Name"
                    value={funderName}
                    onChange={(e) => setFunderName(e.target.value)}
                    fullWidth
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreateFunder}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Add Funder'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFunderModal;
