// default config for all the environments
export const config = {
  language: 'en',
  supportedLocales: ['en', 'he']
};

// write here every environmet configuration
const modes = {
  development: {
    ...config,
    API_URL: 'http://localhost:3000/api/v1/admin'
  },
  staging: {
    ...config,
    API_URL: 'https://dashboard-stg-api.togethering.global/api/v1/admin'
  },
  production: {
    ...config,
    API_URL: 'https://dashboard-prd-api.togethering.global/api/v1/admin'
  }
};


const appMode = modes[process.env.REACT_APP_ENV] || modes.development;

export default appMode;

const useId = {
  development: 236,
  staging: 236,
  qa: 236,
  production: 236,
};

export const usaMode = useId[process.env.REACT_APP_ENV] || useId.development;