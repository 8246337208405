import TrendsIcon from '@material-ui/icons/TrendingUpRounded';

import { TrendsEdit } from './edit';
import { TrendsList } from './list';
import { TrendsCreate } from './create';

export default {
    name: 'trends',
    options: { label: 'Trends', menuParent: '_events' },
    icon: TrendsIcon,
    list: TrendsList,
    edit: TrendsEdit,
    create: TrendsCreate
};