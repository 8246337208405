import React from 'react';
import {
	Show,
	TabbedShowLayout,
	Tab,
	TextField,
	ArrayField,
	SingleFieldList,
	FunctionField,
	ReferenceArrayField,
	ChipField
} from 'react-admin';
import { DateFieldShow } from 'components/Fields';
import LockIcon from '@material-ui/icons/LockRounded';
import { ViewTitle } from '../../../components/AppBar/Titles';


export const TagGroupsShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<TabbedShowLayout>
			<Tab label='General Details'>
				<TextField source='id' label='ID' />
				<FunctionField
					label='Group Name'
					render={(record) => 
						<>
							<TextField source='name' label={false} />
							{ record.is_locked ? <LockIcon style={{ verticalAlign: 'bottom', marginLeft: '5px', width: '15px' }} /> : null }
						</>
                    }
                />
				<ArrayField source='taggable_types' label='Taggables'>
					<SingleFieldList>
						<FunctionField render={(record) => <ChipField record={{ name: record }} source='name' />} />
					</SingleFieldList>
				</ArrayField>
				<DateFieldShow source='created_at' label='Created At' />
				<DateFieldShow source='updated_at' label='Updated At' />
			</Tab>

			<Tab label='Associated Tags'>
				<ReferenceArrayField source='tags_ids' reference='tags' label={false}>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);
