import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	filter: {
		display: 'flex',
		marginBottom: "-18px",
		"& > *": {
			marginRight: "6px",
		},
	},
});

const PolymorphicFilter = (props) => {
    const classes = useStyles();

	return (
		<div label={props.label} className={classes.filter}>
			{props.children}
		</div>
	);
};

export default PolymorphicFilter;