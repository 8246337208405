import React from 'react';
import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField,
	ChipField,
	ArrayField,
	FunctionField,
	ReferenceArrayField,
	SingleFieldList
} from 'react-admin';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const RolesShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='name' label='Name' />
			<BooleanField source='is_default' label='Default?' />
			<ArrayField source='applies_to'>
				<SingleFieldList linkType={false}>
					<FunctionField render={(record) => <ChipField record={{ name: record }} source='name' />} />
				</SingleFieldList>
			</ArrayField>
			<ReferenceArrayField source='permissions_ids' reference='permissions' label='Assigned Permissions'>
				<SingleFieldList>
					<ChipField source='name' />
				</SingleFieldList>
			</ReferenceArrayField>
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);
