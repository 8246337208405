import React from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	SelectArrayInput,
	ReferenceArrayInput,
	AutocompleteArrayInput
  } from 'react-admin';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';

export const RolesEdit = (props) => (
	<Edit title='Edit Role' {...props}>
		<SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar hidedelete />}>
		<TextInput source='name' validate={required()} fullWidth />
			<BooleanInput source='is_default' label='Default?' fullWidth />
			<SelectArrayInput
				validate={required()}
				source='applies_to'
				label='Applies To'
				choices={[
					{ id: 'User', name: 'User' },
					{ id: 'AdminUser', name: 'Admin User' }
				]}
				fullWidth
			/>
			<ReferenceArrayInput
				label='Assigned Permissions'
				source='permissions_ids'
				reference='permissions'
				perPage={25}
				filterToQuery={(searchText) => ({
					q: searchText,
					_q_op: 'isw',
					_autocomplete: true
				})}
				sort={{ field: 'id', order: 'ASC' }}
				fullWidth
			>
				<AutocompleteArrayInput
					matchSuggestion={(filterValue, suggestion) => true}
					optionText='name'
				/>
			</ReferenceArrayInput>
		</SimpleForm>
	</Edit>
);
