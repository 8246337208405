import React from 'react';
import { 
	Create, 
	SimpleForm, 
	NumberInput, 
	ReferenceInput,
	AutocompleteInput,
	required
} from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const TrendsCreate = (props) => {
	return (
		<>
			<Create {...props} title='Create Trend'>
				<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
					<ReferenceInput
						label='State'
						source='state_id'
						reference='states'
						perPage={1000}
						filterToQuery={(searchText) => ({
							q: searchText,
							_q_op: 'isw',
							_autocomplete: true
						})}
						sort={{ field: 'iso', order: 'ASC' }}
						fullWidth
					>
						<AutocompleteInput
							resettable
							matchSuggestion={(filterValue, suggestion) => true}
							optionText={(choice) =>
								choice && choice.id ? `${choice.name} (${choice.iso})` : 'None'
							}
						/>
					</ReferenceInput>
					<ReferenceInput
						label='Domain'
						source='domain_id'
						reference='domains'
						perPage={1000}
						filterToQuery={(searchText) => ({
							q: searchText,
							_q_op: 'isw',
							_autocomplete: true
						})}
						sort={{ field: 'name', order: 'ASC' }}
						fullWidth
					>
						<AutocompleteInput
							resettable
							matchSuggestion={(filterValue, suggestion) => true}
							optionText='name'
						/>
					</ReferenceInput>
					<ReferenceInput
						label='Category'
						source='category_id'
						reference='categories'
						perPage={1000}
						filterToQuery={(searchText) => ({
							q: searchText,
							_q_op: 'isw',
							_autocomplete: true
						})}
						sort={{ field: 'name', order: 'ASC' }}
						fullWidth
					>
						<AutocompleteInput
							resettable
							matchSuggestion={(filterValue, suggestion) => true}
							optionText='name'
						/>
					</ReferenceInput>
            		<NumberInput source='value' validate={required()} fullWidth />
				</SimpleForm>
			</Create>
		</>
	);
}