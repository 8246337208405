import EventsIcon from '@material-ui/icons/AppsRounded';

import { EventsEdit } from './edit';
import { EventsList } from './list';
import { EventsShow } from './show';
import { EventsCreate } from './create';

export default {
    name: 'events',
    options: { label: 'Main', menuParent: '_events' },
    icon: EventsIcon,
    list: EventsList,
    show: EventsShow,
    edit: EventsEdit,
    create: EventsCreate
};