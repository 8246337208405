import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, SimpleShowLayout, TextField, ChipField } from 'react-admin';

export const StatesShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<ChipField source='iso' label='ISO' />
			<TextField source='name' label='Name' />
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);