import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useInput, Labeled } from 'react-admin';
import React, { useCallback, useMemo, useRef, useEffect, useState, memo } from 'react';
import { debounce } from 'lodash';

const validateRichText = (value) => {
    if (!value) {
        return 'Required';
    }
    const strippedContent = value.replace(/<[^>]*>/g, '').trim();
    if (!strippedContent) {
        return 'Required';
    }
    return undefined;
};

const RichTextInput = memo(({ label, source, validate = false }) => {
    const combinedValidate = useMemo(() => {
        return validate 
            ? [validateRichText]
            : null
    }, [validate]);

    const {
        input: { value, onChange },
        meta: { touched, error },
        isRequired,
    } = useInput({
        source,
        validate: combinedValidate,
    });

    const [editorContent, setEditorContent] = useState(value || '');

    useEffect(() => {
        if (value !== editorContent) {
            setEditorContent(value || '');
        }
    }, [value]);

    const quillRef = useRef(null);

    const debouncedOnChange = useMemo(
        () => debounce(onChange, 300),
        [onChange]
    );

    const handleEditorChange = useCallback(
        (content, delta, source, editor) => {
            setEditorContent(content);
            debouncedOnChange(content);
        },
        [debouncedOnChange]
    );

    const quillModules = useMemo(
        () => ({
            toolbar: [
                // Dropdowns for headers
                [{ header: [1, 2, 3, false] }],
                // Bold, italic, underline buttons
                ['bold', 'italic', 'underline'],
                // Dropdown for text colors
                [{ color: [] }, { background: [] }],
                // Lists (ordered and unordered)
                [{ list: 'ordered' }, { list: 'bullet' }],
                // Link
                ['link'],
            ],
        }),
        []
    );

    const quillFormats = useMemo(
        () => [
            'header', 
            'bold', 
            'italic', 
            'underline', 
            'color', 
            'background', 
            'list', 
            'bullet', 
            'ordered', 
            'link',
        ],
        []
    );

    const editorField = (
        <>
            <ReactQuill
                ref={quillRef}
                value={editorContent}
                onChange={handleEditorChange}
                modules={quillModules}
                formats={quillFormats}
                style={{
                    height: '200px',
                    marginBottom: '40px',
                    border: touched && error ? '2px solid red' : 'none',
                }}
            />
            {touched && error && (
                <span
                    style={{
                        color: 'red',
                        fontSize: '12px',
                        marginTop: '5px',
                        display: 'block',
                    }}
                >
                    {error === 'ra.validation.required' ? 'Required' : error}
                </span>
            )}
        </>
    );

    return (
        <div style={{ marginTop: '1em', width: '100%' }}>
            {label ? (
                <Labeled label={label} source={source} isRequired={isRequired} fullWidth>
                    {editorField}
                </Labeled>
            ) : (
                editorField
            )}
        </div>
    );
});

export default RichTextInput;
