import PersonIcon from '@mui/icons-material/Person';

import { Resource } from 'react-admin';

import usersViews from './User';
import userTypesViews from './UserType';

const UserRoutes = [
  <Resource
    name='_users'
    icon={PersonIcon}
    options={{ label: 'Users', isMenuParent: true }}
  />,
  <Resource {...usersViews} />,
  <Resource {...userTypesViews} />
];
export default UserRoutes;
