import React from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	AutocompleteInput
} from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const UserTypesCreate = (props) => (
	<Create {...props} title='Create Type'>
		<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
			<TextInput source='name' validate={required()} fullWidth />
			<TextInput source='description' fullWidth multiline />
			<BooleanInput source='requires_mfa' label='MFA?' fullWidth />
			<BooleanInput source='requires_kyc' label='KYC?' fullWidth />
			<BooleanInput source='is_client_accessible' label='Client Accessible?' fullWidth />
			<ReferenceInput
				label='Associated Role'
				source='associated_role_id'
				reference='roles'
				perPage={25}
				filterToQuery={(searchText) => ({
					q: searchText,
					_q_op: 'isw',
					_autocomplete: true
				})}
				sort={{ field: 'name', order: 'ASC' }}
				fullWidth
			>
				<AutocompleteInput 
					resettable 
					validate={required()} 
					matchSuggestion={(filterValue, suggestion) => true}
					optionText='name' 
				/>
			</ReferenceInput>
			<ArrayInput source="benefits">
				<SimpleFormIterator>
					<TextInput label={false} />
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Create>
);