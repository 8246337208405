import React from "react";
import { useTranslate } from "react-admin";
import { Field, Form } from "react-final-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";


const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
);

const InputNonce = ({ meta: { touched, error }, input: inputProps, ...props }) => (
	<input 
	error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
        disabled
		hidden
	/>
);

const formStyles = makeStyles(
	(theme) => ({
		form: {
			padding: "0 1em 1em 1em",
		},
		input: {
			marginTop: "1em",
		},
		verifyButton: {
			width: "100%",
			marginTop: "30px",
			backgroundColor: "#00d4b5",
		},
		icon: {
			marginRight: theme.spacing(1),
		},
    qrWrapper: {
      textAlign: "center"
    },
		instructions: {
			maxWidth: "300px",
			textAlign: "center",
			margin: "0 auto"
		},
    qr: {
      textAlign: "center"
    }
	}),
	{ name: "RaLoginForm" }
);

export const QrCode = ({ onSubmit, loading,qrImage, nonce,email, ...props }) => {
	const classes = formStyles(props);
	const translate = useTranslate();
	const validate = (values) => {
		const errors = { code: undefined };
		if (!values.code ) { errors.code = translate("ra.validation.required"); }
		return errors;
	};

	return (
    <div>
      <h2>Two-Factor Authentication</h2>
      {
        <div className={classes.qrWrapper}>
          {qrImage ? (
            <>
              <p className={classes.instructions}>Scan the QR code</p>
              <a className={classes.qr}>
                <img src={qrImage} alt="QrCode" />
              </a>
            </>
          ) : 
		  <p className={classes.instructions}>Please enter the code listed in the Google Authenticator App</p>
		  }
        </div>
      }

      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  id="code"
                  name="code"
                  component={Input}
                  type="password"
                  label="Verification Code"
                  disabled={loading}
                  defaultValue=''
                />
                <Field
                  autoFocus
                  id="email"
                  name="email"
                  component={InputNonce}
                  type="text"
                  label="Verification Nonce"
                  disabled={loading}
                  defaultValue={email}
                />
              </div>
            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.verifyButton}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                Submit
              </Button>
            </CardActions>
          </form>
        )}
      />
    </div>
  );
};
