import DomainsIcon from '@material-ui/icons/BubbleChartRounded';

import { DomainsEdit } from './edit';
import { DomainsList } from './list';
import { DomainsShow } from './show';
import { DomainsCreate } from './create';

export default {
    name: 'domains',
    options: { label: 'Domains', menuParent: '_events' },
    icon: DomainsIcon,
    list: DomainsList,
    show: DomainsShow,
    edit: DomainsEdit,
    create: DomainsCreate
};