import React from 'react';
import {
	List, 
	ArrayField,
	SingleFieldList, 
	TextField,
	ChipField,
	FunctionField
} from 'react-admin';
import TagGroupsFilter from './filter';
import LockIcon from '@material-ui/icons/LockRounded';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const TagGroupsList = (props) => (
	<List
		title='Tag Groups'
		filters={<TagGroupsFilter />}
		pagination={<Paginator />}
		perPage={15}
		sort={{ field: 'id', order: 'DESC' }}
		{...props}
		empty={<EmptyPage create/>}
		actions={<ListActions listExporterType='Settings' />}
	>
		<DataGridList show isDeletable={record => !record.is_locked}>
			<FunctionField
				label='Group Name'
				render={(record) => 
					<>
						<TextField source='name' label={false} />
						{ record.is_locked ? <LockIcon style={{ verticalAlign: 'bottom', marginLeft: '5px', width: '15px' }} /> : null }
					</>
				}
			/>
			<ArrayField source='taggable_types' label='Taggables'>
				<SingleFieldList>
					<FunctionField render={(record) => <ChipField record={{ name: record }} source='name' />} />
				</SingleFieldList>
			</ArrayField>
		</DataGridList>
	</List>
);
