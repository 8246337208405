import React from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	SelectArrayInput,
	ReferenceArrayInput,
	AutocompleteArrayInput
} from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const RolesCreate = (props) => (
	<Create title='Create Role' {...props}>
		<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar hidedelete />}>
			<TextInput source='name' validate={required()} fullWidth />
			<BooleanInput source='is_default' label='Default?' fullWidth />
			<SelectArrayInput
				validate={required()}
				source='applies_to'
				label='Applies To'
				choices={[
					{ id: 'User', name: 'User' },
					{ id: 'AdminUser', name: 'Admin User' }
				]}
				fullWidth
			/>
			<ReferenceArrayInput
				label='Assigned Permissions'
				source='permissions_ids'
				reference='permissions'
				perPage={25}
				filterToQuery={(searchText) => ({
					q: searchText,
					_q_op: 'isw',
					_autocomplete: true
				})}
				sort={{ field: 'id', order: 'ASC' }}
				fullWidth
			>
				<AutocompleteArrayInput
					matchSuggestion={(filterValue, suggestion) => true}
					optionText='name'
				/>
			</ReferenceArrayInput>
		</SimpleForm>
	</Create>
);