import EventsIcon from "@material-ui/icons/NewReleasesRounded";

import { Resource } from "react-admin";
import eventsViews from './Event';
import trendsViews from './Trend';
import storiesViews from './Story';
import domainsViews from './Domain';
import categoriesViews from './Category';

const EventRoutes = [
  <Resource
    name="_events"
    icon={EventsIcon}
    options={{ label: "Events", isMenuParent: true }}
  />,
  <Resource {...eventsViews} />,
  <Resource {...storiesViews} />,
  <Resource {...categoriesViews} />,
  <Resource {...domainsViews} />,
  <Resource {...trendsViews} />
];
export default EventRoutes;
