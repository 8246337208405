import React from 'react';
import LeadFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import ApproveLead from 'components/Buttons/ApproveLead';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { 
	List, 
	TextField, 
	FunctionField, 
	ChipField 
} from 'react-admin';

export const LeadsList = (props) => (
	<List
		filters={<LeadFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage />}
		actions={<ListActions hideCreateButton />}
	>
		<DataGridList customButtons={[({record, resource}) => <ApproveLead record={record} resource={resource} />]}>
			<TextField source='name' label='Name' />
			<FunctionField
				label='Status'
				render={(record) => 
					<ChipField 
						record={record} 
						source='status' 
						variant='outlined'
						style={{
							color: record.status === 'pending-approval' ? '#B38819' : '#149D00',
							borderColor: record.status === 'pending-approval' ? '#B38819' : '#149D00',
							backgroundColor: record.status === 'pending-approval' ? '#FDF5D9' : '#DCF2D9'
						}}
					/>
				}
			/>
		</DataGridList>
	</List>
);