import React from 'react';
import {
	Create,
	TextInput,
	SelectInput,
	ArrayInput,
	SimpleFormIterator,
	BooleanInput,
	TabbedForm,
	FormTab,
	required,
	email
} from 'react-admin';
import { CreateToolbar } from 'components/Toolbar/CreateToolbar';

export const AccountsCreate = (props) => (
  	<Create {...props} title='Create Account'>
		<TabbedForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
			<FormTab label='Personal Details'>
				<TextInput
					source='first_name'
					label='First Name'
					validate={required()}
					fullWidth
				/>
				<TextInput
					source='last_name'
					label='Last Name'
					validate={required()}
					fullWidth
				/>
				<TextInput
					source='email'
					label='Email'
					validate={[email(), required()]}
					fullWidth
				/>
				<BooleanInput source='email_notifications_on' label='Email Notifications?' fullWidth />
			</FormTab>

			<FormTab label='Credentials'>
				<ArrayInput label='' source='credentials'>
					<SimpleFormIterator disableRemove disableReordering>
						<TextInput
							label='Email'
							source='sid'
							validate={[email(), required()]}
							fullWidth
						/>
						<TextInput
							label='Password'
							type='password'
							source='secret'
							fullWidth
						/>
						<SelectInput
							label='Status'
							source='status'
							validate={required()}
							fullWidth
							choices={[
								{ id: 'pending', name: 'Pending Confirmation' },
								{ id: 'active', name: 'Active' },
								{ id: 'locked', name: 'Locked' },
								{ id: 'suspended', name: 'Suspended' },
							]}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
		</TabbedForm>
  	</Create>
);