import config from '../../../config';
import Button from '../../Buttons/Button';
import React, { useState, useCallback } from 'react';
import ApproveIcon from '@mui/icons-material/CloudDone';
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, ListItemText, Select, MenuItem, Table, TableBody, TableCell, TableRow, TableHead, Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 800,
    margin: 'auto',
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    verticalAlign: 'middle',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  select: {
    minWidth: 200,
  },
  rowEven: {
    backgroundColor: theme.palette.action.hover,
  },
  orgExistsText: {
    marginLeft: theme.spacing(0),
    color: theme.palette.text.secondary,
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
}));

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);
  return [state, toggle];
};

const fetchCollaborationsMatch = async (resource, id) => {
  const response = await fetch(
    `${config.API_URL}/resources/${resource}/${id}/collaborations_organizations_match`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'withCredentials': true,
      }),
      credentials: 'include',
    }
  );
  const data = await response.json();
  return data.data;
};

const approveAndCreateOrganizations = async (resource, id, collaborationCreationChoices) => {
  await fetch(
    `${config.API_URL}/resources/${resource}/${id}/approve_and_create_organizations`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'withCredentials': true,
      }),
      credentials: 'include',
      body: JSON.stringify({ collaboration_creation_choices: collaborationCreationChoices }),
    }
  );
};

const ApproveLead = ({ resource, record }) => {
  const classes = useStyles();
  const [collaborations, setCollaborations] = useState([]);
  const [collaborationCreationChoices, setCollaborationCreationChoices] = useState({});
  const [organizationList, setOrganizationList] = useState([]);
  const [createNewOrgChoices, setCreateNewOrgChoices] = useState({});
  const [openInitialConfirm, toggleInitialConfirm] = useToggle();
  const [openFinalConfirm, toggleFinalConfirm] = useToggle();

  const handleInitialConfirm = async () => {
    toggleInitialConfirm();
    try {
      const { matchMap: data } = await fetchCollaborationsMatch(resource, record.id);
      if (data && data.collaborations && data.list) {
        const groupedCollaborations = data.collaborations.reduce((acc, collab) => {
          if (!acc[collab.name]) {
            acc[collab.name] = { ids: [], match: collab.match };
          }
          acc[collab.name].ids.push(collab.id);
          return acc;
        }, {});

        setCollaborations(groupedCollaborations);
        setOrganizationList(data.list);

        setCollaborationCreationChoices(
          Object.keys(groupedCollaborations).reduce((acc, name) => ({
            ...acc,
            [name]: groupedCollaborations[name].match || '', // Use name as the key
          }), {})
        );

        setCreateNewOrgChoices(
          Object.keys(groupedCollaborations).reduce((acc, name) => ({
            ...acc,
            [name]: false, // Default checkbox state to unchecked
          }), {})
        );
      } else {
        console.error('Collaborations or list data is undefined or null.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    toggleFinalConfirm();
  };

  const handleSelectChange = (collabName, selectedValue) => {
    setCollaborationCreationChoices(prev => ({ ...prev, [collabName]: selectedValue }));
  };

  const handleCheckboxChange = (collabName) => {
    setCreateNewOrgChoices(prev => {
      const newState = { ...prev, [collabName]: !prev[collabName] };
      if (newState[collabName]) {
        setCollaborationCreationChoices(prevChoices => ({
          ...prevChoices,
          [collabName]: 'new', // Set a value to indicate new organization creation
        }));
      } else {
        setCollaborationCreationChoices(prevChoices => ({
          ...prevChoices,
          [collabName]: '', // Reset selection if checkbox is unchecked
        }));
      }
      return newState;
    });
  };

  const handleCreateOrganizations = async () => {
    // Validation: Ensure that all non-checkbox collaborations have an organization selected
    for (const collabName in createNewOrgChoices) {
      if (!createNewOrgChoices[collabName] && !collaborationCreationChoices[collabName]) {
        alert(`Please select an organization for ${collabName} or check the box to create a new one.`);
        return;
      }
    }

    const submissionData = Object.entries(collaborationCreationChoices).reduce((acc, [name, value]) => {
      const ids = collaborations[name].ids;
      acc[ids] = value; // Send array of IDs as key
      return acc;
    }, {});

    toggleFinalConfirm();
    console.log('Creating organizations for these collaborations:', submissionData);
    await approveAndCreateOrganizations(resource, record.id, submissionData);
    window.location.reload();
  };

  return (
    <>
      <Button label="Approve" onClick={toggleInitialConfirm}>
        <ApproveIcon />
      </Button>

      <Dialog open={openInitialConfirm} onClose={toggleInitialConfirm} classes={{ paper: classes.paper }}>
        <DialogTitle>Approve Lead</DialogTitle>
        <DialogContent>
          <p>This procedure will convert the current lead into an organization and will provide options to review each associated collaboration. For collaborations without existing organizational matches, you will have the opportunity to initiate the creation of a new organization designated as 'not-claimed'.</p>
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={toggleInitialConfirm} />
          <Button label="Continue" onClick={handleInitialConfirm} />
        </DialogActions>
      </Dialog>

      <Dialog open={openFinalConfirm} onClose={toggleFinalConfirm} classes={{ paper: classes.paper }}>
        <DialogTitle>Confirm Collaborations</DialogTitle>
        <DialogContent>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Collaboration</TableCell>
                <TableCell className={classes.tableHeadCell}>Map To</TableCell>
                <TableCell className={classes.tableHeadCell}>Create New Organization In Status "Not Claimed"</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(collaborations).length > 0 ? (
                Object.entries(collaborations).map(([name, { ids, match }], index) => (
                  <TableRow key={name} className={index % 2 === 0 ? classes.rowEven : ''}>
                    <TableCell className={classes.tableCell}>
                      <ListItemText primary={name} primaryTypographyProps={{ className: classes.collaborationName }} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Select
                        value={collaborationCreationChoices[name] || ''}
                        onChange={(e) => handleSelectChange(name, e.target.value)}
                        displayEmpty
                        className={classes.select}
                        disabled={createNewOrgChoices[name]} // Disable if checkbox is checked
                      >
                        <MenuItem value="">
                          <em>Select an organization</em>
                        </MenuItem>
                        {organizationList.map(org => (
                          <MenuItem key={org} value={org}>
                            {org}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={createNewOrgChoices[name]}
                            onChange={() => handleCheckboxChange(name)}
                            color="primary"
                            className={classes.checkbox}
                          />
                        }
                        label="New Organization"
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No collaborations available.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button label="Back" onClick={toggleFinalConfirm} />
          <Button label="Confirm" onClick={handleCreateOrganizations} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveLead;
