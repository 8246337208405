import LeadsIcon from '@material-ui/icons/StarRounded'

import { LeadsEdit } from './edit';
import { LeadsList } from './list';
import { LeadsShow } from './show';

export default {
    name: 'leads',
    options: { label: 'Leads', menuParent: '_organizations' },
    icon: LeadsIcon,
    list: LeadsList,
    show: LeadsShow,
    edit: LeadsEdit,
};