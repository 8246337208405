import FundersIcon from '@mui/icons-material/AttachMoneyRounded';

import { FundersEdit } from './edit';
import { FundersList } from './list';
import { FundersShow } from './show';
import { FundersCreate } from './create';

export default {
    name: 'funders',
    options: { label: 'Funders', menuParent: '_organizations' },
    icon: FundersIcon,
    list: FundersList,
    show: FundersShow,
    edit: FundersEdit,
    create: FundersCreate
};