import React from 'react';
import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField,
	ArrayField,
	SingleFieldList,
	FunctionField,
	ReferenceField
} from 'react-admin';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const UserTypesShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='name' label='Name' />
			<TextField source='description' label='Description' />
			<BooleanField source='requires_mfa' label='MFA?' />
			<BooleanField source='requires_kyc' label='KYC?' />
			<BooleanField source='is_client_accessible' label='Client Accessible?' />
			<ReferenceField label='Associated Role' source='associated_role_id' reference='roles'>
				<TextField source='name' label='Name' />
			</ReferenceField>
			<ArrayField source='benefits'>
				<SingleFieldList linkType={false} style={{display: 'grid'}}>
					<FunctionField render={(record) => <TextField record={{ name: record }} source='name' />} />
				</SingleFieldList>
			</ArrayField>
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);
