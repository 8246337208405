import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, SimpleShowLayout, TextField, ReferenceField, BooleanField } from 'react-admin';

export const ServicesShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='name' label='Name' />
			<ReferenceField label='Service Category' source='category_id' reference='service_categories'>
				<TextField source='name' label='Name' />
			</ReferenceField>
			<BooleanField source='is_global' label='Global?' />
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);