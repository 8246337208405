import Moment from "react-moment";
import moment from "moment";
import { FunctionField, Labeled } from "react-admin";
export const DateField = ({ record, source, label }) => {
  return (
    <FunctionField
      label={label}
      records={record[source]}
      render={(record) =>
        record[source] && (
          <Moment format="MM/DD/YYYY">{moment(record[source])}</Moment>
        )
      }
    />
  );
};
export const DateFieldShow = ({ record, source, label }) => {
  return (
    <Labeled label={label}>
      <FunctionField
        label={label}
        records={record[source]}
        render={(record) =>
          record[source] && (
            <Moment format="MM/DD/YYYY">{moment(record[source])}</Moment>
          )
        }
      />
    </Labeled>
  );
};
