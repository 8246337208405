import ServicesIcon from '@material-ui/icons/AppsRounded';

import { ServicesEdit } from './edit';
import { ServicesList } from './list';
import { ServicesShow } from './show';
import { ServicesCreate } from './create';

export default {
    name: 'services',
    options: { label: 'Main', menuParent: '_services' },
    icon: ServicesIcon,
    list: ServicesList,
    show: ServicesShow,
    edit: ServicesEdit,
    create: ServicesCreate
};