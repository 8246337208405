import React from 'react';
import FilterContainer from 'components/containers/Filter';
import { DebouncedTextField, EmailField } from 'components/Fields';

const AccountFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='isw_first_name' label='First Name' />
		<DebouncedTextField source='isw_last_name' label='Last Name' />
		<EmailField source='isw_email' label='Email' />
	</FilterContainer>
);

export default AccountFilters;