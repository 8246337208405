import ImageEye from "@material-ui/icons/RemoveRedEye";
import PropTypes from "prop-types";
import { linkToRecord } from "ra-core";
import * as React from "react";
import { memo } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

const ShowButton = ({
  basePath = "",
  label = "VIEW",
  record,
  icon = defaultIcon,
  ...rest
}) => (
  <Button
    component={Link}
    to={`${linkToRecord(basePath, record && record.id)}/show`}
    label={label}
    onClick={stopPropagation}
    {...rest}
  >
    {icon}
  </Button>
);

const defaultIcon = <ImageEye />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

ShowButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

const PureShowButton = memo(
  ShowButton,
  (props, nextProps) =>
    (props.record && nextProps.record
      ? props.record.id === nextProps.record.id
      : props.record == nextProps.record) && // eslint-disable-line eqeqeq
    props.basePath === nextProps.basePath &&
    props.to === nextProps.to
);

export default PureShowButton;
