import userTypesIcon from '@material-ui/icons/CategoryRounded';

import { UserTypesList } from './list';
import { UserTypesShow } from './show';
import { UserTypesEdit } from './edit';
import { UserTypesCreate } from './create';


export default {
	name: 'user_types',
	options: { label: 'Types', menuParent: '_users' },
	icon: userTypesIcon,
	list: UserTypesList,
	show: UserTypesShow,
	edit: UserTypesEdit,
	create: UserTypesCreate
};