import React from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	SelectInput
  } from 'react-admin';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';

export const SettingsEdit = (props) => (
	<Edit title={<EditTitle source='name' />} {...props}>
		<SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
			<TextInput source='key' validate={required()} fullWidth />
			<TextInput source='value' validate={required()} fullWidth />
			<SelectInput
				validate={required()}
				source='data_type'
				label='Data Type'
				choices={[
					{ id: 'string', name: 'String' },
					{ id: 'integer', name: 'Integer' },
					{ id: 'decimal', name: 'Decimal' },
					{ id: 'boolean', name: 'Boolean' },
					{ id: 'csv', name: 'CSV' }
				]}
				fullWidth
			/>
			<TextInput source='description' fullWidth />
			<BooleanInput source='is_client_accessible' label='Client Accessible?' fullWidth />
		</SimpleForm>
	</Edit>
);
