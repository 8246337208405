import React from 'react';
import ServiceFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { List, TextField, ReferenceField, BooleanField } from 'react-admin';

export const ServicesList = (props) => (
	<List
		filters={<ServiceFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions listExporterType='Services' />}
	>
		<DataGridList isDeletable={record => false}>
			<TextField source='name' label='Name' />
			<ReferenceField label='Service Category' source='category_id' reference='service_categories'>
				<TextField source='name' label='Name' />
			</ReferenceField>
			<BooleanField source='is_global' label='Global?' />
		</DataGridList>
	</List>
);