import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, TabbedShowLayout, TextField, RichTextField, Tab, ArrayField, ReferenceField, ChipField,
				Datagrid, NumberField } from 'react-admin';

export const FundersShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<TabbedShowLayout>
			<Tab label='General Details'>
				<TextField source='id' label='ID' />
				<TextField source='name' label='Name' />
				<RichTextField source='description' label='Description' />
				<DateFieldShow source='created_at' label='Created At' />
				<DateFieldShow source='updated_at' label='Updated At' />
			</Tab>

			<Tab label='Fundings'>
				<ArrayField label='Fundings' source='sponsorships' fullWidth>
					<Datagrid>
						<TextField source='id' label='ID' />
						<ReferenceField label='Organization' source='receiver_id' reference='organizations'>
							<TextField source='name' label='Name' />
						</ReferenceField>
						<ChipField source='year' label='Year' />
						<NumberField source='amount' label='Amount' />
					</Datagrid>
				</ArrayField>
			</Tab>

		</TabbedShowLayout>
	</Show>
);