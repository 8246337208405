import React from 'react';
import { EditToolbar } from 'components/Toolbar/EditToolbar';
import { EditTitle } from '../../../components/AppBar/Titles';
import RichTextInput from 'components/Fields/Inputs/RichTextInput';
import { FileUploaderPlaceholder } from 'components/FileUploaderPlaceholder';
import { 
	Edit, TabbedForm, FormTab, TextInput, ReferenceInput, AutocompleteInput, 
	DateInput, ReferenceArrayInput, AutocompleteArrayInput, SelectInput, ArrayInput, 
	SimpleFormIterator, FileInput, FileField, NumberInput, required,
	BooleanInput
} from 'react-admin';

export const EventsEdit = (props) => {
	return (
		<>
			<Edit title={<EditTitle source='title' />} {...props}>
				<TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
					<FormTab label='General Details'>
						<SelectInput
							source='type'
							label='Type'
							validate={required()}
							choices={[
								{ id: 'Incident', name: 'Incident' },
								{ id: 'NewsItem', name: 'News Item' },
								{ id: 'Insight', name: 'Insight' },
							]}
							fullWidth
						/>
						<TextInput source='title' validate={required()} fullWidth />
						<RichTextInput source='description' label='Description' />
						<ReferenceInput
							label='Story'
							source='story_id'
							reference='stories'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'name', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteInput
								resettable
								matchSuggestion={(filterValue, suggestion) => true}
								optionText={(choice) =>
									choice && choice.id ? `${choice.name}` : 'None'
								}
							/>
						</ReferenceInput>
						<ReferenceInput
							label='State'
							source='state_id'
							reference='states'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'iso', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteInput
								resettable
								matchSuggestion={(filterValue, suggestion) => true}
								optionText={(choice) =>
									choice && choice.id ? `${choice.name} (${choice.iso})` : 'None'
								}
							/>
						</ReferenceInput>
						<TextInput source='city' label='City' fullWidth />
						<TextInput source='institution' label='Institution' fullWidth />
						<ReferenceArrayInput
							label='Domains'
							source='domains_ids'
							reference='domains'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>
						<ReferenceArrayInput
							label='Categories'
							source='categories_ids'
							reference='categories'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>
						<ReferenceArrayInput
							label='Services'
							source='services_ids'
							reference='services'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>
						<ReferenceArrayInput
							label='Fields of Operation'
							source='fields_of_operation_ids'
							reference='fields_of_operation'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>
						<DateInput source='date' label='Date' variant='standard' fullWidth />
						<NumberInput source='severity' label='Severity' variant='standard' fullWidth />	
						<BooleanInput source='is_global' label='Global?' variant='standard' fullWidth />
						<BooleanInput source='is_important' label='Important?' variant='standard' fullWidth />
						<SelectInput
							source='status'
							label='Status'
							validate={required()}
							choices={[
								{ id: 'draft', name: 'Draft' },
								{ id: 'active', name: 'Active' }
							]}
							fullWidth
						/>				
					</FormTab>

					<FormTab label='Media'>
						<TextInput source='data_source' label='Data Source' variant='standard' fullWidth />
						<TextInput source='media_source' label='Media Source' variant='standard' fullWidth />

						<ArrayInput label='External Links' source='external_links'>
							<SimpleFormIterator disableReordering>
								<TextInput source='url' label='URL' variant='standard' fullWidth />	
								<TextInput source='display_name' label='Display Name' variant='standard' fullWidth />	
							</SimpleFormIterator>
						</ArrayInput>

						<ArrayInput label='Attachments' source='attachments'>
							<SimpleFormIterator disableReordering>
								<FileInput
									source='file_file'
									accept='image/*,video/*,application/pdf'
									label='File'
									validate={required()}
									placeholder={<FileUploaderPlaceholder />}
									fullWidth
								>
									<FileField source='src' title='key' />
								</FileInput>
								<TextInput source='caption' label='Caption' fullWidth />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>

					<FormTab label='Taggings'>
						<ReferenceArrayInput
							label=''
							source='tags_ids'
							reference='tags'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								'$group.taggable_types$': ['Event', 'Incident', 'NewsItem', 'Insight'],
								_autocomplete: false
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText={(choice) => {
										const name = choice.parent ? `${choice.parent.name} -> ${choice.name}` : choice.name
										return choice && choice.id ? name : 'None'
									}
								}
							/>
						</ReferenceArrayInput>
					</FormTab>
				</TabbedForm>
			</Edit>
		</>
	);
}