import React, { useState, useEffect} from 'react';
import { ColorInput } from 'components/Fields/Inputs/ColorInput';
import RichTextInput from 'components/Fields/Inputs/RichTextInput';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { Create, FormTab, TextInput, required, TabbedForm, ArrayInput,
				SimpleFormIterator, FormDataConsumer, ReferenceInput, AutocompleteInput,
			  useDataProvider } from 'react-admin';

export const FieldsOfOperationCreate = (props) => {
	const [availableServices, setAvailableServices] = useState([]); 
	
	const dataProvider = useDataProvider();

	    // Fetch all services to determine which ones haven't been selected yet
			useEffect(() => {
        dataProvider
            .getList('services', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'name', order: 'ASC' },
                filter: {},
            })
            .then(({ data }) => {
							setAvailableServices(data); 
            });
    }, [dataProvider]);

	return (
		<>
			<Create {...props} title='Create Field Of Operation'>
			<TabbedForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
					<FormTab label='General Details'>
						<TextInput source='name' validate={required()} fullWidth />
						<RichTextInput source='description' label='Description'/>
						<ColorInput source='color' label='Color' validate={required()} />
					</FormTab>

					<FormTab label='Services'>
						<ArrayInput source='services' label='Services'>
							<SimpleFormIterator disableReordering>
									<FormDataConsumer>
											{({ getSource, formData, scopedFormData }) => {
													const selectedServicesIds = formData?.services?.map(service => service?.id) || [];
													
													// Filter out already selected services from available services
													const filteredServicesIds = availableServices
														.filter(service => !selectedServicesIds.includes(service?.id))
														.map(service => service?.id);
													
													return (
															<ReferenceInput
																	source={getSource('id')}
																	reference='services'
																	label='Service'
																	validate={required()}
																	perPage={1000}
																	filter={{ id: filteredServicesIds }}
																	filterToQuery={(searchText) => ({
																			q: searchText,
																			_q_op: 'isw',
																			_autocomplete: true,
																	})}
																	variant='standard'
																	fullWidth
															>
																	<AutocompleteInput
																			resettable
																			matchSuggestion={(filterValue, suggestion) => true}
																			optionText='name'
																	/>
															</ReferenceInput>
													);
											}}
									</FormDataConsumer>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				</TabbedForm>
			</Create>
		</>
	);
}