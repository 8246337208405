import React from 'react';
import {
  	SelectInput,
  	TextInput
} from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const PermissionsFilter = (props) => (
  	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField label='Name' source='isw_name' />
		<DebouncedTextField label='Resource' source='isw_resource' />
		<DebouncedTextField label='Action' source='isw_action' />
  	</FilterContainer>
);

export default PermissionsFilter;
