import React from 'react';
import FilterContainer from 'components/containers/Filter';
import { DebouncedTextField, RangeFilter } from 'components/Fields';
import { 
	ReferenceInput, 
	AutocompleteInput, 
	ReferenceArrayInput,
	AutocompleteArrayInput,
	SelectInput, 
	DateInput,
	BooleanInput
} from 'react-admin';

const EventFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<SelectInput
			source='type'
			label='Type'
			variant='standard'
			choices={[
				{ id: 'Incident', name: 'Incident' },
				{ id: 'NewsItem', name: 'News Item' },
				{ id: 'Insight', name: 'Insight' },
			]}
		/>
		<SelectInput
			source='status'
			label='Status'
			variant='standard'
			choices={[
				{ id: 'draft', name: 'Draft' },
				{ id: 'active', name: 'Active' }
			]}
		/>
		<DebouncedTextField source='ic_title' label='Title' />
		<DebouncedTextField source='ic_description' label='Description' />
		<RangeFilter source='date' label='Date'>
			<DateInput source='min_date' label='Date (from)' variant='standard' />
			<DateInput source='max_date' label='Date (to)' variant='standard' />
		</RangeFilter>
		<ReferenceInput
			label='Story'
			source='story_id'
			reference='stories'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'name', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceInput>
		<ReferenceInput
			label='State'
			source='state_id'
			reference='states'
			perPage={1000}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'iso', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText={(choice) =>
					choice && choice.id ? `${choice.name} (${choice.iso})` : 'None'
				}
			/>
		</ReferenceInput>
		<ReferenceArrayInput
			label='Domains'
			source='$domains->id$'
			reference='domains'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'id', order: 'ASC' }}
		>
			<AutocompleteArrayInput
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceArrayInput>
		<ReferenceArrayInput
			label='Classifications'
			source='$categories->id$'
			reference='categories'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'id', order: 'ASC' }}
		>
			<AutocompleteArrayInput
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceArrayInput>
		<ReferenceArrayInput
			label='Tags'
			source='$tags->id$'
			reference='tags'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'id', order: 'ASC' }}
		>
			<AutocompleteArrayInput
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceArrayInput>
		<BooleanInput source='is_important' label='Important?' />
		<BooleanInput source='is_global' label='Global?' />
		<DebouncedTextField source='ic_data_source' label='Data Source' />
		<DebouncedTextField source='ic_media_source' label='Media Source' />
		<DebouncedTextField source='severity' label='Severity' />
	</FilterContainer>
);

export default EventFilters;