import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { FieldsOfOperationServicesField } from '../components/FieldsOfOperationServices/field';
import { 
	Show, 
	TabbedShowLayout, 
	Tab,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	TextField,
	UrlField,
	Datagrid,
	ReferenceField,
	NumberField,
	ImageField,
	ArrayField,
	RichTextField,
	FunctionField,
	Labeled,
} from 'react-admin';

export const OrganizationsShow = (props) => {
	return (
	<Show title={<ViewTitle source='title' />} {...props}>
		<TabbedShowLayout>
			<Tab label='General Details'>
				<TextField source='id' label='ID' />
				<TextField source='name' label='Name' />
				<TextField source='creator_name' label='Registered By' />
				<ChipField source='size' label='Size'/>
				<ChipField source='status' label='Status' />
				<ArrayField source='budgets' label='Budgets'>
					<Datagrid>
						<ChipField source='year' />
						<NumberField source='amount' />
					</Datagrid>
				</ArrayField>
				<ReferenceArrayField source='contacts_ids' reference='contacts' label='Key People'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>
				<ChipField source='collaborators_count' label='Collaborators Count' />
				<UrlField source='website_link' label='Website Link' />
				<ImageField source='logo.url' label='Logo' />
			</Tab>
		
			<Tab label='Key Activities'>

				<FieldsOfOperationServicesField
					source='fields_of_operation_services_breakdown'
					label='Fields of Operation Services'
				/>

				<TextField source='agenda' label='Agenda' />

			</Tab>

			<Tab label='Target Audience'>
				<ReferenceArrayField source='target_audiences_ids' reference='tags' label='Main'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>

				<TextField source='additional_target_audiences' label='Additional' />
			</Tab>

			<Tab label='Cooperation & Partnerships'>
				<ArrayField label={false} source='collaborations' fullWidth>
					<Datagrid>
						<TextField source='id' />

						<ReferenceField
							label='Collaborator Name'
							source='id'
							reference='organizations'
						>
							<TextField source='name' label='Name' />
						</ReferenceField>				

						<ArrayField label='Context' source='breakdown' fullWidth>
							<Datagrid>
								<FunctionField
									render={(record) => {

										return (
											<>
											  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
													<Labeled label='Field Of Operation'>
														<ChipField 
															record={{ name: record.field_of_operation_name ?? 'Pending assignment' }} 
															source='name' 
															style={{ backgroundColor: '#CA441A', color: 'white' }} 
														/>
													</Labeled>
													<Labeled label='Service'>
														<ChipField 
															record={{ name: record.service_name }} 
															source='name' 
															style={{ backgroundColor: '#3f51b5', color: 'white' }} 
														/>
													</Labeled>
												</div>
											</>
										);
									}}
								/>
								<RichTextField source='description' label='Details' />
							</Datagrid>
						</ArrayField>

					</Datagrid>
				</ArrayField>
			</Tab>

			<Tab label='Fundings'>
				<ArrayField label={false} source='sponsorships' reference='sponsorships' fullWidth>
					<Datagrid>
						<TextField source='id' />
						
						<ReferenceField
							label='Funder Name'
							source='funder_id'
							reference='funders'
						>
							<TextField source='name' label='Name' />
						</ReferenceField>

						<TextField source='year' label='Year' />
						<NumberField source='amount' label='Amount' />
						
					</Datagrid>
				</ArrayField>
			</Tab>
		</TabbedShowLayout>
	</Show>
)};