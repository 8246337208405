import React from 'react';
import RichTextInput from 'components/Fields/Inputs/RichTextInput';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const FundersCreate = (props) => {
	return (
		<>
			<Create {...props} title='Create User'>
				<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
						<TextInput source='name' validate={required()} fullWidth />
						<RichTextInput source='description' label='Description'/>
				</SimpleForm>
			</Create>
		</>
	);
}