import React from 'react';
import { EditToolbar } from 'components/Toolbar/EditToolbar';
import RichTextInput from 'components/Fields/Inputs/RichTextInput';
import { FieldsOfOperationServicesInput } from '../components/FieldsOfOperationServices/input';
import { 
	Edit, 
	TabbedForm, 
	FormTab, 
	TextInput, 
	SelectInput, 
	ArrayInput,
	SimpleFormIterator,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	required,
	ReferenceInput,
	AutocompleteInput
} from 'react-admin';

export const LeadsEdit = (props) => {
	return (
		<>
			<Edit title='Edit Lead' {...props}>
				<TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
					<FormTab label='General Details'>
						<TextInput source='name' label='Name' validate={required()} fullWidth />
						<TextInput source='creator_name' label='Registered By' validate={required()} fullWidth />
						<SelectInput
							source='size'
							label='Size'
							validate={required()}
							choices={[
								{ id: 'GrassrootsOrStartup', name: 'Grassroots/Startup' },
								{ id: 'MediumNationalOrRegionalFocus', name: 'Medium - NationalOrRegionalFocus' },
								{ id: 'MajorInternationalAndMajorFunding', name: 'Major - InternationalAndMajorFunding' },
							]}
							fullWidth
						/>
						<ArrayInput source="contacts" label='Key People'>
								<SimpleFormIterator disableReordering>
										<TextInput source="" label="Name" validate={required()} fullWidth />
								</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				
					<FormTab label='Key Activities'>
						<FieldsOfOperationServicesInput source='fields_of_operation_services_breakdown' />

						<TextInput source='agenda' label='Agenda' validate={required()} fullWidth multiline />
					</FormTab>

					<FormTab label='Target Audience'>
						<ReferenceArrayInput
							label='Focus Areas'
							source='target_audiences_ids'
							reference='tags'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								'$group.name$': 'TargetAudience',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>

						<TextInput source='additional_target_audiences' label='Additional' fullWidth multiline />
					</FormTab>

					<FormTab label='Cooperation & Partnerships'>
						<ArrayInput label='' source='collaborations'>
							<SimpleFormIterator disableReordering>
								<TextInput source="name" label="Name" validate={required()} fullWidth />

								<ReferenceInput
									label='Field of Operation'
									source='field_of_operation_id'
									reference='fields_of_operation'
									validate={required()}
									perPage={1000}
									filterToQuery={(searchText) => ({
										q: searchText,
										_q_op: 'isw',
										_autocomplete: true
									})}
									sort={{ field: 'name', order: 'ASC' }}
									fullWidth
								>
									<AutocompleteInput
										resettable
										matchSuggestion={(filterValue, suggestion) => true}
										optionText={(choice) =>
											choice && choice.id ? choice.name : 'None'
										}
									/>
								</ReferenceInput>

								<ReferenceInput
									label='Service'
									source='service_id'
									reference='services'
									validate={required()}
									perPage={1000}
									filterToQuery={(searchText) => ({
										q: searchText,
										_q_op: 'isw',
										_autocomplete: true
									})}
									sort={{ field: 'name', order: 'ASC' }}
									fullWidth
								>
									<AutocompleteInput
										resettable
										matchSuggestion={(filterValue, suggestion) => true}
										optionText={(choice) =>
											choice && choice.id ? choice.name : 'None'
										}
									/>
								</ReferenceInput>

								<RichTextInput source='description' label='Details' fullWidth multiline />
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				</TabbedForm>
			</Edit>
		</>
	);
}