import React from 'react';
import { Chip } from '@mui/material';
import FunderFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { FunctionField, List, TextField, Labeled } from 'react-admin';

const formatter = new Intl.NumberFormat('en-US');

export const FundersList = (props) => (
	<List
		filters={<FunderFilters />}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions listExporterType="Funders" />}
	>
		<DataGridList>
			<TextField source="name" label="Name" />
			<FunctionField
				label="Total Fundings (Most Recent Year)"
				render={(record) => {
					if (!record.most_recent_funding) return null;
					const { year, amount } = record.most_recent_funding;

					// Use the formatter
					const formattedAmount = typeof amount === 'number'
						? formatter.format(amount)
						: formatter.format(Number(amount));

					return (
						<Labeled label={`Year: ${year}`}>
							<Chip label={formattedAmount} />
						</Labeled>
					);
				}}
				sortable={false}
			/>
		</DataGridList>
	</List>
);
