import React from 'react';
import { 
	ReferenceInput,
	AutocompleteInput,
	NumberInput
} from 'react-admin';
import { RangeFilter } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const TrendFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<ReferenceInput
			label='State'
			source='state_id'
			reference='states'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'iso', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText={(choice) =>
					choice && choice.id ? `${choice.name} (${choice.iso})` : 'None'
				}
			/>
		</ReferenceInput>
		<ReferenceInput
			label='Domain'
			source='domain_id'
			reference='domains'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'name', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceInput>
		<ReferenceInput
			label='Classification'
			source='category_id'
			reference='categories'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'name', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceInput>
		<RangeFilter source='value' label='Value'>
			<NumberInput source='min_value' label='Value (from)' variant='standard' />
			<NumberInput source='max_value' label='Value (to)' variant='standard' />
		</RangeFilter>
	</FilterContainer>
);

export default TrendFilters;