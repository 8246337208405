import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const TagGroupsFilter = (props) => (
  	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField label='Name' source='name' />
  	</FilterContainer>
);

export default TagGroupsFilter;
