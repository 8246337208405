import get from 'lodash/get';
import React, { useState} from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { generateYears } from './helpers/years';
import AddFunderModal from './components/AddFunderModal';
import { EditToolbar } from 'components/Toolbar/EditToolbar';
import { EditTitle } from '../../../components/AppBar/Titles';
import RichTextInput from 'components/Fields/Inputs/RichTextInput';
import { FileUploaderPlaceholder } from 'components/FileUploaderPlaceholder';
import { FieldsOfOperationServicesInput } from '../components/FieldsOfOperationServices/input';
import { 
	Edit, 
	TabbedForm, 
	FormTab, 
	TextInput, 
	SelectInput, 
	ArrayInput,
	ReferenceInput,
	AutocompleteInput,
	SimpleFormIterator,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	FormDataConsumer,
	required,
	NumberInput,
	FileInput,
	ImageField
} from 'react-admin';

export const OrganizationsEdit = (props) => {
	const [isModalOpen, setModalOpen] = useState(false); // Modal open state
	const [funderInputKey, setFunderInputKey] = useState(0); // Key to force re-render of ReferenceInput

	// Function to open the modal
	const handleOpenModal = () => {
			setModalOpen(true);
	};

	// Function to close the modal
	const handleCloseModal = () => {
			setModalOpen(false);
	};

	const handleFunderCreated = () => {
		// Force the ReferenceInput to refresh by changing its key
		setFunderInputKey((prevKey) => prevKey + 1);
	};

	return (
		<>
			<Edit title={<EditTitle source='title' />} {...props}>
				<TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
					<FormTab label='General Details'>
						<TextInput source='name' label='Name' validate={required()} fullWidth />
						<TextInput source='creator_name' label='Registered By' fullWidth />
						<SelectInput
							source='status'
							label='Status'
							validate={required()}
							choices={[
								{ id: 'active', name: 'Active' },
								{ id: 'inactive', name: 'Inactive' },
								{ id: 'not-claimed', name: 'Not Claimed' },
							]}
							fullWidth
						/>
						<SelectInput
							source='size'
							label='Size'
							choices={[
								{ id: 'GrassrootsOrStartup', name: 'Grassroots/Startup' },
								{ id: 'MediumNationalOrRegionalFocus', name: 'Medium - NationalOrRegionalFocus' },
								{ id: 'MajorInternationalAndMajorFunding', name: 'Major - InternationalAndMajorFunding' },
							]}
							fullWidth
						/>
						
						<ArrayInput label='Budgets' source='budgets' >
							<SimpleFormIterator disableReordering>
								<SelectInput
										label='Year'
										source='year'
										choices={generateYears(2015, new Date().getFullYear())}
								/>

								<NumberInput
										source='amount'
										label='Amount'
										validate={required()}
										fullWidth
								/>
							</SimpleFormIterator>
						</ArrayInput>

						<ArrayInput label='Key People' source='contacts' >
							<SimpleFormIterator disableReordering>
								<TextInput source='name' label='' validate={required()} fullWidth />
							</SimpleFormIterator>
						</ArrayInput>

						<TextInput
								source='website_link'
								label='Website Link'
								type='url'
								validate={(value) => {

									if (!value) {
										return undefined;
									}
									
									// Simple URL validation using regex
									const urlPattern = new RegExp(
											'^(https?:\\/\\/)?' + // validate protocol
											'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
											'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
											'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
											'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
											'(\\#[-a-z\\d_]*)?$',
											'i'
									);
								
									return urlPattern.test(value) ? undefined : 'Must be a valid URL';
								}}
								fullWidth
						/>

						<FileInput
								source='logo_file'
								accept='image/*'
								label='Logo'
								placeholder={<FileUploaderPlaceholder />}
								fullWidth
							>
								<ImageField source='src' />
						</FileInput>
					</FormTab>
				
					<FormTab label='Key Activities'>
						<FieldsOfOperationServicesInput source='fields_of_operation_services_breakdown' />

						<TextInput source='agenda' label='Agenda' fullWidth multiline />
					</FormTab>

					<FormTab label='Target Audience'>
						<ReferenceArrayInput
							label='Target Audiences'
							source='target_audiences_ids'
							reference='tags'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								'$group.name$': 'TargetAudience',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>

						<TextInput source='additional_target_audiences' label='Additional' fullWidth multiline />
					</FormTab>

					<FormTab label='Cooperation & Partnerships'>

						<ArrayInput label='' source='collaborations'>
							<Box mb={3} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: '8px', width: '100%' }}>
								<SimpleFormIterator disableReordering>
									<ReferenceInput
											label="Name"
											source="id"
											reference="organizations"
											fullWidth
									>
											<AutocompleteInput
													optionText="name"
													validate={required()}
													fullWidth
											/>
									</ReferenceInput>

									<ArrayInput source='breakdown' label='Context'>
											<SimpleFormIterator>
												<FormDataConsumer>
													{({ formData, scopedFormData, getSource }) => {
														// Get selected fields of operation from FieldsOfOperationServicesInput
														const selectedFields = formData.fields_of_operation_services_breakdown || [];

														// Create a list of available fields of operation to choose from
														const availableFields = selectedFields.map(field => ({
																id: field.field_of_operation?.id,
																name: field.field_of_operation?.name
														}));

														return (
																<ReferenceInput
																		label="Field of Operation"
																		source={getSource("field_of_operation_id")}
																		reference="fields_of_operation"
																		validate={required()}
																		perPage={1000}
																		filter={{ id: availableFields.map(f => f.id) }}
																		filterToQuery={(searchText) => ({
																				q: searchText,
																				_q_op: "isw",
																				_autocomplete: true,
																		})}
																		sort={{ field: "name", order: "ASC" }}
																		variant="standard"
																		fullWidth
																>
																		<AutocompleteInput
																				matchSuggestion={(filterValue, suggestion) => true}
																				optionText="name"
																		/>
																</ReferenceInput>
														);
													}}
												</FormDataConsumer>

												<FormDataConsumer>
													{({ formData, scopedFormData, getSource }) => {
													// Get the selected field of operation to restrict services
													const selectedFieldId = scopedFormData?.field_of_operation_id;

													// Find the corresponding services for the selected field
													const selectedField = formData.fields_of_operation_services_breakdown.find(
															field => field?.field_of_operation?.id === selectedFieldId
													);

													// Get available services for the selected field
													const availableServices = selectedField
															? selectedField.services.map(service => ({
																	id: service?.id,
																	name: service?.name
															}))
															: [];

														return (
																<ReferenceInput
																		label="Service"
																		source={getSource("service_id")}
																		reference="services"
																		validate={required()}
																		perPage={1000}
																		filter={{ id: availableServices.map(s => s.id) }}
																		filterToQuery={(searchText) => ({
																				q: searchText,
																				_q_op: "isw",
																				_autocomplete: true,
																		})}
																		sort={{ field: "name", order: "ASC" }}
																		variant="standard"
																		fullWidth
																>
																		<AutocompleteInput
																				matchSuggestion={(filterValue, suggestion) => true}
																				optionText="name"
																		/>
																</ReferenceInput>
														);
													}}
												</FormDataConsumer>


												<RichTextInput source='description' label='Details' validate={required()} />
											</SimpleFormIterator>
									</ArrayInput>
									
								</SimpleFormIterator>
							</Box>
						</ArrayInput>
					</FormTab>

					<FormTab label='Fundings'>
						<ArrayInput label='' source='sponsorships'>
							<SimpleFormIterator disableReordering>
							<FormDataConsumer>
									{({ 
											getSource, 
											formData
									}) =>
											<>
													<TextInput 
															source={getSource('receiver_id')} 
															defaultValue={formData.id} 
															style={{ display: 'none' }} 
													/>
											</>
									}
							</FormDataConsumer>

								<FormDataConsumer>
									{({ 
										getSource, 
										scopedFormData
									}) =>
										<>
											<ReferenceInput
												label='Funder'
												source={getSource('funder_id')}
												reference='funders'
												validate={required()}
												perPage={1000}
												variant='standard'
												filterToQuery={(searchText) => ({
													q: searchText,
													_q_op: 'isw',
													_autocomplete: true
												})}
												disabled={get(scopedFormData, 'id')} 
												key={funderInputKey}
												fullWidth
											>
												<AutocompleteInput
													resettable
													matchSuggestion={(filterValue, suggestion) => true}
													optionText={(choice) => 
														choice && choice?.id ? choice.name : 'None'
													}
												/>
											</ReferenceInput>
											<Box mt={2}>
												<Button
														startIcon={<AddIcon />}
														onClick={handleOpenModal}
														variant="outlined"
														color="primary"
														size="small"
												>
														Add New Funder
												</Button>
											</Box>
										</>
									}
								</FormDataConsumer>

								<SelectInput
										label='Year'
										source='year'
										choices={generateYears(2015, new Date().getFullYear())}
								/>

								<NumberInput
										source='amount'
										label='Amount'
										variant='standard'
										min={0} 
										validate={required()}
										fullWidth
								/>
							</SimpleFormIterator>
						</ArrayInput>
					</FormTab>
				</TabbedForm>
			</Edit>
			{/* AddFunderModal: this is the modal for creating a new funder */}
			<AddFunderModal
				open={isModalOpen}
				onClose={handleCloseModal} // Close modal callback
				onFunderCreated={handleFunderCreated} // Callback to handle funder creation
			/>
		</>
	);
}
