import React from 'react';
import {
	List, 
	BooleanField, 
	TextField
} from 'react-admin';
import UserTypeFilters from './filter';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const UserTypesList = (props) => (
	<List
		title='User Types'
		filters={<UserTypeFilters />}
		pagination={<Paginator />}
		perPage={15}
		sort={{ field: 'id', order: 'DESC' }}
		{...props}
		empty={<EmptyPage create/>}
		actions={<ListActions />}
	>
		<DataGridList isDeletable={record => record.is_deletable}>
			<TextField source='name' label='Name' />
			<BooleanField source='requires_mfa' label='MFA?' />
			<BooleanField source='requires_kyc' label='KYC?' />
			<BooleanField source='is_client_accessible' label='Client Accessible?' />
		</DataGridList>
	</List>
);
