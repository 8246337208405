import moment from "moment";
import React, { useEffect, useState } from "react";
import Welcome from "./Welcome";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useVersion, useDataProvider } from "react-admin";
import config from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import { Typography } from "@material-ui/core";

import DatePicker from "react-datepicker";
import CardWithIcon from "./CardWithIcon";
import CardWithPrecentage from "./CardWithPrecentage";


import ExpertIcon from "@material-ui/icons/StarBorderRounded";
import clickToSignUpIcon from "@material-ui/icons/Assignment";
import clickToRegIcon from "@material-ui/icons/AssignmentTurnedIn";
import RegToTrialIcon from "@material-ui/icons/Dvr";
import SuccessfulTrialIcon from "@material-ui/icons/CheckBox";
import TrialToNPCIcon from "@material-ui/icons/ShoppingCart";
import NPCIcon from "@material-ui/icons/ShoppingBasket";
import MAUIcon from "@material-ui/icons/Person";
import PlanIcon from "@material-ui/icons/LocalActivity";
import MultiplePlansIcon from "@material-ui/icons/PersonAdd";
import MultipleTherapistsIcon from "@material-ui/icons/GroupAdd";
import PromotionUseIcon from "@material-ui/icons/LocalOffer";
import ActiveExpertIcon from "@material-ui/icons/Star";
import LeadsIcon from "@material-ui/icons/Mail";


const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  grid:{  
    display:"grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    columnGap: "1em",
    rowGap: "1em"
},
datePicker:{display:"flex",
justifyContent:"flex-start"}
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const Dashboard = () => {
  const [data, setData] = useState({});
  const version = useVersion();
  const [startTime, onChangestartTime] = useState(()=>{
    const date = new Date();
    return  moment(new Date(date.getFullYear(), date.getMonth(), 1)).startOf('day').toDate();})
    const [endTime, onChangeEndTime] = useState(moment().endOf('day').toDate());

  const getData = async (startTime,endTime) => {
    
    return await fetch(
        `${config.API_URL}/resources/stats?start_time=${startTime ? encodeURIComponent(startTime/1000):''}&end_time=${endTime ?encodeURIComponent(endTime/1000):''}`,
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              "withCredentials":true
            }),
            credentials:"include"
        }
          ).then((res) => res.json());
  
    }
  

  const fetchData = async (startTime,endTime) => {
    try {
      const {data} = await getData(startTime,endTime);
      setData(data);
    } catch {}
  };

  useEffect(() => {

  }, [version,startTime,endTime]); // eslint-disable-line

  return (
    <>
      <Welcome />
    </>
  );
};

export default Dashboard;
