import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, SimpleShowLayout, TextField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';

export const MajorServiceCategoriesShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='name' label='Name' />
			<ReferenceArrayField source='service_categories_ids' reference='service_categories' label='Service Categories'>
				<SingleFieldList>
					<ChipField source='name' />
				</SingleFieldList>
			</ReferenceArrayField>
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);