import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { FieldsOfOperationServicesField } from '../components/FieldsOfOperationServices/field';
import { 
	Show, 
	TabbedShowLayout, 
	Tab,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	Datagrid,
	TextField,
	RichTextField,
	ArrayField,
	FunctionField,
	ReferenceField
} from 'react-admin';

export const LeadsShow = (props) => (
	<Show title={<ViewTitle source='title' />} {...props}>
		<TabbedShowLayout>
			<Tab label='General Details'>
				<TextField source='id' label='ID' />
				<TextField source='name' label='Name' />
				<TextField source='creator_name' label='Registered By' />
				<ChipField source='size' label='Size'/>
				<ArrayField source='contacts' label='Key People'>
					<SingleFieldList>
						<FunctionField render={(record) => <ChipField record={{ name: record }} source='name' />} />
					</SingleFieldList>
				</ArrayField>
			</Tab>
		
			<Tab label='Key Activities'>
				<FieldsOfOperationServicesField 
					source='fields_of_operation_services_breakdown'
					label='Fields of Operation Services' 
				/>

				<TextField source='agenda' label='Agenda' />
			</Tab>

			<Tab label='Target Audience'>
				<ReferenceArrayField source='target_audiences_ids' reference='tags' label='Main'>
					<SingleFieldList>
						<ChipField source='name' />
					</SingleFieldList>
				</ReferenceArrayField>

				<TextField source='additional_target_audiences' label='Additional' />
			</Tab>

			<Tab label='Cooperation & Partnerships'>
				<ArrayField label={false} source='collaborations' fullWidth>
					<Datagrid>
						<TextField source='id' />
						<TextField source='name' />
						
						<ReferenceField source='service_id' reference='services' label='Service'>
							<ChipField source='name' />
						</ReferenceField>

						<ReferenceField source='field_of_operation_id' reference='fields_of_operation' label='Field Of Operation'>
							<FunctionField render={(record) => {
								return (
									<ChipField 
										record={record}
										source='name'
										style={{ backgroundColor: record.color || '#ccc', color: 'white' }}
									/>
								)
							}} />
						</ReferenceField>
						
						<RichTextField source='description' label='Details' />
					</Datagrid>
				</ArrayField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);