import './App.css';

import Menu from 'components/Menu/Menu';
import dataProvider from 'providers/Data';
import Login from 'components/Login/Login';
import { useEffect, useState } from 'react';
import { MyLightTheme } from 'themes/Themes';
import { Admin, Resource } from 'react-admin';
import { useHistory } from 'react-router-dom';
import MyLayout from 'components/AppBar/Layout';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import Dashboard from 'components/Dashboard/Dashboard';
import BackdropLoader from 'components/Loaders/BackdropLoader';

import UserRoutes from 'resources/Users';
import EventRoutes from 'resources/Events';
import SystemRoutes from 'resources/System';
import ServiceRoutes from 'resources/Services';
import LocalizationRoutes from 'resources/Localization';
import OrganizationRoutes from 'resources/Organizations';
import FieldOfOperationRoutes from 'resources/FieldsOfOperation';

import authProvider from "providers/Authentication";

let previousUrlPath; 

const App = () => {
	const history = useHistory();
	const [showLoader, setShowLoader] = useState(false);
	const [theme, setTheme] = useState(
		localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
	);

	// if the current page is home (dashboard), don't show the loader:
	useEffect(() => {
		previousUrlPath = history.location.hash;
		if (history.location.hash.charAt(history.location.hash.length - 1) === "/") {
			setShowLoader(false);
		} else {
			setShowLoader(true);
		}
	}, []); 

	// whenever the url changes, show loader (unless it is the dashboard):
	useEffect(() => {
		return history.listen(() => {
			// the following code prevents the bug where the user
			// clicks the browser back button from a create/show/edit page and add a filter:
			if (
				previousUrlPath.split("").filter((letter) => letter === "/").length >= 2 &&
				history.location.hash.split("").filter((letter) => letter === "/").length < 2
			) {
				window.location.reload();
			}
			
			previousUrlPath = history.location.hash;

			// here we are showing the loader if the url is not home (dashboard):
			if (history.location.hash.charAt(history.location.hash.length - 1) === "/") {
				setShowLoader(false);
			} else {
				setShowLoader(true);
			}
		});
	}, [history]);

	const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
		allowMissing: true,
	});

	return (
		<Admin
			dashboard={Dashboard}
			layout={MyLayout}
			theme={MyLightTheme}
			authProvider={authProvider}
			dataProvider={dataProvider}
			loginPage={Login}
			menu={Menu}
			i18nProvider={i18nProvider}
		>
			{showLoader ? <BackdropLoader /> : <></>}

			{EventRoutes}
			{OrganizationRoutes}
			{ServiceRoutes}
			{FieldOfOperationRoutes}
			{LocalizationRoutes}
			{UserRoutes}
			{SystemRoutes}
			<Resource name='attachments' />
			<Resource name='tags' />
			<Resource name='contacts' />
			<Resource name='lead_collaborations' />
			<Resource name='sponsorships' />
		</Admin>
	);
};

export default App;