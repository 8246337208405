import { UploadAndSave } from "./UploadAndSave";
import DeleteWithConfirmation from "./DeleteButtonWithConfirmation";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "react-admin";

const useStyles = makeStyles({
	toolbar: {
		justifyContent: "space-between"
	}
});

export const EditToolbar = (props) => {
	const classes = useStyles();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			<UploadAndSave label="Save" redirect={props.redirectTo || 'show'} beforeSaveCallback={props.beforeSaveCallback || (() => {})} submitOnEnter />
			{!props.hidedelete && <DeleteWithConfirmation />}
		</Toolbar>
	);
};
