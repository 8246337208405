import FieldsOfOperationIcon from '@material-ui/icons/AppsRounded';

import { FieldsOfOperationEdit } from './edit';
import { FieldsOfOperationList } from './list';
import { FieldsOfOperationShow } from './show';
import { FieldsOfOperationCreate } from './create';

export default {
    name: 'fields_of_operation',
    options: { label: 'Main', menuParent: '_fields_of_operation' },
    icon: FieldsOfOperationIcon,
    list: FieldsOfOperationList,
    show: FieldsOfOperationShow,
    edit: FieldsOfOperationEdit,
    create: FieldsOfOperationCreate
};