import rolesIcon from '@material-ui/icons/EmojiEventsRounded';

import { RolesList } from './list';
import { RolesShow } from './show';
import { RolesEdit } from './edit';
import { RolesCreate } from './create';

export default {
	name: 'roles',
	options: { label: 'Roles', menuParent: '_system' },
	icon: rolesIcon,
	list: RolesList,
	show: RolesShow,
	edit: RolesEdit,
	create: RolesCreate
};