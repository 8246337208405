import { createTheme } from "@material-ui/core/styles";

export const MyDarkTheme = createTheme({
  fontFamily: "'Spartan', sans-serif",
  palette: {
    type: "dark",
    primary: { main: "#248ad3" },
    secondary: { main: "#616161e6" },
    error: { main: "#e5455c" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'Spartan', sans-serif",
  },
  overrides: {
    // changing the bulk actions button styling:
    RaTopToolbar: {
      root: {
        paddingTop: "0",
        paddingBottom: "0",
        color: "white !important",
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        // backgroundColor: "#fff",
      },
    },
    RaFileInput: {
      dropZone: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    RaLayout: {
      root: {
        overflowY: "auto",
      },
      appFrame: {
        marginTop: "65px !important"
      },
      content: {
        paddingTop: '25px !important',
        paddingLeft: '25px'
      },
    },

    RaCreate: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaList: {
      content: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaShow: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaEdit: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          WebkitTextFillColor: "white !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:hover": {
          WebkitTextFillColor: "white !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:focus": {
          WebkitTextFillColor: "white !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        maxWidth: "400px !important",
      },
    },

    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
    },

    MuiButton: {
      label: {
        fontWeight: "bold",
        color: "#248ad3",
      },
    },
    MuiLink: {
      root: {
        color: "#248ad3",
      },
    },
    RaReferenceField: {
      link: {
        color: "#248ad3",
      },
    },

    RaSaveButton: {
      button: {
        backgroundColor: "#616161e6",
        "&:hover": {
          backgroundColor: "grey",
        },
      },
    },
    RaSidebar: {
      root: {
        marginTop: "15px",
        position: "inherit",
        overflowY: "visible",
        zIndex: 1100,
        marginRight: 10,
      },
    },
    RaLoginForm: {
      loginButton: {
        backgroundColor: "#616161e6",
        "&:hover": {
          backgroundColor: "grey",
        },
      },
      verifyButton: {
        backgroundColor: "#616161e6",
        "&:hover": {
          backgroundColor: "grey",
        },
      },
    },

    RaListToolbar: {
      toolbar: {
        marginTop: "0px !important",
      },
    },
  },
});

export const MyLightTheme = createTheme({
  fontFamily: "'Spartan', sans-serif",
  palette: {
    type: "light",
    primary: { main: "#40beb1" },
    secondary: { main: "#fff" },
    error: { main: "#e5455c" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'Spartan', sans-serif",
  },
  overrides: {
    // changing the bulk actions button styling:
    RaTopToolbar: {
      root: {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    RaFileInput: {
      dropZone: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    RaLayout: {
      root: {
        overflowY: "auto",
      },
      content: {
        paddingTop: '35px',
        paddingLeft: '25px !important',
        backgroundColor: '#fff'
      }
    },

    RaCreate: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaList: {
      content: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaShow: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },

    RaEdit: {
      card: {
        overflow: "auto !important",
        minHeight: "auto",
      },
    },
    MuiInputBase: {
      input: {
        "&[type='color']": { height: '30px' },
        "&[type='color']:hover": { cursor: 'pointer' },
        "&[type='color']::-webkit-color-swatch-wrapper": { padding: 0 },
        "&[type='color']::-webkit-color-swatch": { border: 0 },
        "&:-webkit-autofill": {
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:hover": {
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
        "&:-webkit-autofill:focus": {
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0px 1000px background-color inset !important",
          boxShadow: "0 0 0px 1000px background-color inset !important",
          transition: "background-color 5000s ease-in-out 0s !important",
        },
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        maxWidth: "400px !important",
      },
    },
    RaDeleteWithUndoButton: {
      deleteButton: {
        "& span": {
          color: "#e5455c"
        },
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: "bold",
      },
    },
    MuiAvatar: {
      root: {
        padding: "5px",
        border: "1px solid #eeeeee"
      }
    },
    MuiButton: {
      label: {
        fontWeight: "bold",
        color: "#003148",
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiLink: {
      root: {
        color: "#41cea5",
      },
    },

    RaReferenceField: {
      link: {
        color: "#41cea5",
      },
    },
    RaSaveButton: {
      button: {
        boxShadow: 'none',
        borderRadius: '25px',
        backgroundColor: "#222222",
        "&:hover": {
          boxShadow: 'none',
          backgroundColor: "#003148",
        },
        "& span": {
          color: '#ffffff'
        }
      },
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: 'none'
      }
    },
    RaSidebar: {
      root: {
        paddingTop: "40px",
        position: "inherit",
        overflowY: "visible",
        zIndex: 1100,
        marginRight: 10,
      },
    },
    RaLoginForm: {
      loginButton: {
        boxShadow: 'none',
        borderRadius: '25px',
        backgroundColor: "#222222",
        "&:hover": {
          boxShadow: 'none',
          backgroundColor: "#003148",
        }
      },
      verifyButton: {
        boxShadow: 'none',
        borderRadius: '25px',
        backgroundColor: "#222222",
        "& span": {
          color: '#fff'
        },
        "&:hover": {
          boxShadow: 'none',
          backgroundColor: "#003148",
        }
      },
    },
    RaAppBar: {
      toolbar: {
        color: "#003148"
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none'
      },
      elevation4: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.01), 0px 1px 10px 0px rgba(0,0,0,0.12)'
      }
    },
    MuiAppBar: {
      root: {
        zIndex: '9999'
      }
    },
    MuiChip: {
      label: {
        textTransform: 'capitalize'
      }
    }
  },
});
