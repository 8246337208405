import StatesIcon from '@material-ui/icons/MapRounded';

import { StatesEdit } from './edit';
import { StatesList } from './list';
import { StatesShow } from './show';
import { StatesCreate } from './create';

export default {
    name: 'states',
    options: { label: 'States', menuParent: 'localization' },
    icon: StatesIcon,
    list: StatesList,
    show: StatesShow,
    edit: StatesEdit,
    create: StatesCreate
};