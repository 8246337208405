import CategoriesIcon from '@material-ui/icons/CategoryRounded';

import { CategoriesEdit } from './edit';
import { CategoriesList } from './list';
import { CategoriesShow } from './show';
import { CategoriesCreate } from './create';

export default {
    name: 'categories',
    options: { label: 'Categories', menuParent: '_events' },
    icon: CategoriesIcon,
    list: CategoriesList,
    show: CategoriesShow,
    edit: CategoriesEdit,
    create: CategoriesCreate
};