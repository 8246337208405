import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin';

const FieldOfOperationFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='ic_name' label='Name' />
		<DebouncedTextField source='ic_description' label='Description' />
		<ReferenceArrayField source='services_ids' reference='services' label='Services'>
			<SingleFieldList>
				<ChipField source='name' />
			</SingleFieldList>
		</ReferenceArrayField>
	</FilterContainer>
);

export default FieldOfOperationFilters;