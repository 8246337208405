import React from 'react';
import {
	List, 
	BooleanField, 
	TextField,
	ArrayField,
	SingleFieldList,
	FunctionField,
	ChipField
} from 'react-admin';
import RolesFilters from './filter';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const RolesList = (props) => (
	<List
		title='Settings'
		filters={<RolesFilters />}
		pagination={<Paginator />}
		perPage={15}
		sort={{ field: 'id', order: 'DESC' }}
		{...props}
		empty={<EmptyPage create/>}
		actions={<ListActions listExporterType='Settings' />}
	>
		<DataGridList isDeletable={record => false}>
			<TextField source='name' label='Name' />
			<BooleanField source='is_default' label='Default?' />
			<ArrayField source='applies_to'>
				<SingleFieldList linkType={false}>
					<FunctionField render={(record) => <ChipField record={{ name: record }} source='name' />} />
				</SingleFieldList>
			</ArrayField>
		</DataGridList>
	</List>
);
