import {
  Button as MuiButton,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslate } from "ra-core";
import * as React from "react";

const Button = (props) => {
  const {
    alignIcon = "left",
    children,
    classes: classesOverride,
    className,
    color,
    disabled,
    label,
    size,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const restProps = sanitizeButtonRestProps(rest);

  return isXSmall ? (
    label && !disabled ? (
      <Tooltip title={translate(label, { _: label })}>
        <IconButton
          aria-label={translate(label, { _: label })}
          className={className}
          color={color}
          {...restProps}
        >
          {children}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        className={className}
        color={color}
        disabled={disabled}
        {...restProps}
      >
        {children}
      </IconButton>
    )
  ) : (
    <MuiButton
      className={classnames(classes.button, className)}
      color={color}
      size={size}
      aria-label={label ? translate(label, { _: label }) : undefined}
      disabled={disabled}
      {...restProps}
    >
      {alignIcon === "left" &&
        children &&
        React.cloneElement(children, {
          className: classes[`smallIcon`],
        })}
      {label && (
        <span
          className={classnames({
            [classes.label]: alignIcon === "left",
            [classes.labelRightIcon]: alignIcon !== "left",
          })}
        >
          {translate(label, { _: label })}
        </span>
      )}
      {alignIcon === "right" &&
        children &&
        React.cloneElement(children, {
          className: classes[`smallIcon`],
        })}
    </MuiButton>
  );
};

const useStyles = makeStyles(
  {
    button: {
      display: "inline-flex",
      alignItems: "center",
    },
    label: {
      paddingLeft: "0.5em",
    },
    labelRightIcon: {
      paddingRight: "0.5em",
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  },
  { name: "RaButton" }
);

export const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}) => rest;

Button.propTypes = {
  alignIcon: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

Button.defaultProps = {
  color: "primary",
  size: "small",
};

export default Button;
