import { useForm } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton } from '@mui/material';
import {
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    AutocompleteInput,
    FormDataConsumer,
    useDataProvider,
    required,
} from 'react-admin';

export const FieldsOfOperationServicesInput = ({ source }) => {
    const [availableFields, setAvailableFields] = useState([]); 
    const [availableServices, setAvailableServices] = useState([]);
    const dataProvider = useDataProvider();
    const form = useForm(); 

    // Fetch all fields_of_operation to determine which ones haven't been selected yet
    useEffect(() => {
        dataProvider
            .getList('fields_of_operation', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'name', order: 'ASC' },
                filter: {},
            })
            .then(({ data }) => {
                setAvailableFields(data); 
            });

        dataProvider
            .getList('services', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'name', order: 'ASC' },
                filter: {},
            })
            .then(({ data }) => {
                setAvailableServices(data); 
            });
    }, [dataProvider]);

    return (
        <ArrayInput source={source} label='Fields of Operation Services'>
            <FormDataConsumer>
                {({ formData }) => {
                    // Ensure formData[source] is defined as an empty array if it's not
                    if (!formData[source]) {
                        form.change(source, []); // Initialize the field with an empty array
                    }

                    // Get all selected field_of_operation ids
                    const selectedFieldsOfOperation = (formData[source] || [])
                        .map((field) => field.field_of_operation?.id)
                        .filter(Boolean); // Ensure we only have valid IDs

                    // Filter out already selected fields from available fields
                    const fieldsToShow = availableFields
                        .filter((field) => !selectedFieldsOfOperation.includes(field.id))
                        .map((field) => field.id);

                    // Add a new field_of_operation entry
                    const addFieldOfOperation = () => {
                        const updatedFields = [...(formData[source] || []), {
                            field_of_operation: { id: '', name: '' },
                            services: [],
                        }];

                        // Update the form field directly using the form object
                        form.change(source, updatedFields);
                    };

                    return (
                        <Box>
                            <SimpleFormIterator disableAdd>
                                {/* Field of Operation */}
                                <ReferenceInput
                                    source='field_of_operation.id'
                                    reference='fields_of_operation'
                                    label='Field of Operation'
                                    sort={{ field: 'name', order: 'ASC' }}
                                    filter={{ id: fieldsToShow }} // Filter out already selected fields
                                    perPage={1000}
                                    validate={required()}
                                    filterToQuery={(searchText) => ({
                                        q: searchText,
                                        _q_op: 'isw',
                                        _autocomplete: true,
                                    })}
                                    variant='standard'
                                    fullWidth
                                >
                                    <AutocompleteInput
                                        resettable
                                        matchSuggestion={(filterValue, suggestion) => true}
                                        optionText='name'
                                    />
                                </ReferenceInput>

                                {/* Services related to the Field of Operation */}
                                <FormDataConsumer>
                                    {({ scopedFormData, getSource }) => {
                                        // Ensure scopedFormData and services array are defined
                                        const services = Array.isArray(scopedFormData?.services) ? scopedFormData.services : [];
                                        
                                        // Get available services for the selected field of operation
                                        const availableServicesForFieldOfOperation = availableFields
                                                                                    .find((field) => field.id === scopedFormData?.field_of_operation?.id)?.services 
                                                                                    || [];

                                        const globalServices = availableServices.filter(service => service.is_global);

                                        // Get selected services to filter out already selected services for this field of operation
                                        const selectedServices = services.map((service) => service.id).filter(Boolean);

                                        // Filter out already selected services from available services
                                        const servicesToShow = [...globalServices, ...availableServicesForFieldOfOperation]
                                            .filter((service) => !selectedServices.includes(service.id))
                                            .map((service) => service.id);

                                        // Add a new service
                                        const addService = () => {
                                            const newServices = [...services, { id: '' }];
                                            const updatedFields = formData[source].map((field) => 
                                                field.field_of_operation.id === scopedFormData?.field_of_operation.id 
                                                ? { ...field, services: newServices } 
                                                : field
                                            );

                                            // Update the form data using form.change
                                            form.change(source, updatedFields);
                                        };

                                        // Remove a service
                                        const removeService = (serviceIndex) => {
                                            const updatedServices = services.filter((_, i) => i !== serviceIndex);
                                            const updatedFields = formData[source].map((field) => 
                                                field.field_of_operation.id === scopedFormData.field_of_operation.id 
                                                ? { ...field, services: updatedServices } 
                                                : field
                                            );

                                            // Update the form data using form.change
                                            form.change(source, updatedFields);
                                        };

                                        return (
                                            <Box mt={2}>
                                                {/* List of Services */}
                                                {services.map((service, serviceIndex) => (
                                                    <Box key={serviceIndex} display='flex' alignItems='center' mb={2}>
                                                        <Box flex={1}>
                                                            <ReferenceInput
                                                                label='Service'
                                                                source={getSource(`services.${serviceIndex}.id`)}
                                                                reference='services'
                                                                perPage={1000}
                                                                validate={required()}
                                                                filter={{ id: servicesToShow }} // Filter out already selected services
                                                                filterToQuery={(searchText) => ({
                                                                    q: searchText,
                                                                    _q_op: 'isw',
                                                                    _autocomplete: true,
                                                                })}
                                                                sort={{ field: 'name', order: 'ASC' }}
                                                                fullWidth
                                                            >
                                                                <AutocompleteInput
                                                                    resettable
                                                                    matchSuggestion={() => true}
                                                                    optionText={(choice) =>
                                                                        choice && choice.id ? choice.name : 'None'
                                                                    }
                                                                />
                                                            </ReferenceInput>
                                                        </Box>
                                                        <Box ml={2}>
                                                            <IconButton
                                                                onClick={() => removeService(serviceIndex)}
                                                                color='error'
                                                                aria-label='remove service'
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                ))}

                                                {/* Add Service Button */}
                                                <Box display='flex' justifyContent='left' mt={2} mb={2}>
                                                    <Button
                                                        onClick={addService}
                                                        color='primary'
                                                        variant='outlined'
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add Service
                                                    </Button>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>

                            {/* Custom Add Field of Operation Button */}
                            <Box mt={2}>
                                <Button
                                    onClick={addFieldOfOperation}
                                    color='primary'
                                    variant='contained'
                                    startIcon={<AddIcon />}
                                >
                                    Add Field of Operation
                                </Button>
                            </Box>
                        </Box>
                    );
                }}
            </FormDataConsumer>
        </ArrayInput>
    );
};
