import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, SimpleShowLayout, TextField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';

export const ServiceCategoriesShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='name' label='Name' />
			<ReferenceArrayField source='major_categories_ids' reference='major_service_categories' label='Major Categories'>
				<SingleFieldList>
					<ChipField source='name' />
				</SingleFieldList>
			</ReferenceArrayField>
			<ReferenceArrayField source='services_ids' reference='services' label='Services'>
				<SingleFieldList>
					<ChipField source='name' />
				</SingleFieldList>
			</ReferenceArrayField>
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);