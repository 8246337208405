import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { 
	Create, TextInput, ReferenceInput, AutocompleteInput, required,
  SimpleForm, email, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';

export const UsersCreate = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
	return (
		<>
			<Create {...props} title='Create User'>
				<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
          <TextInput
            source='first_name'
            label='First Name'
            validate={required()}
            fullWidth
          />
          <TextInput
            source='last_name'
            label='Last Name'
            validate={required()}
            fullWidth
          />
          <TextInput
            source='email'
            label='Email'
            validate={[email(), required()]}
            fullWidth
          />
          <TextInput
            source="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            validate={[required()]}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ReferenceInput
            label='Type'
            source='type_id'
            reference='user_types'
            perPage={25}
            filterToQuery={(searchText) => ({
              q: searchText,
              _q_op: 'isw',
              _autocomplete: true
            })}
            validate={required()}
            sort={{ field: 'name', order: 'ASC' }}
            fullWidth
          >
            <AutocompleteInput
              resettable
              matchSuggestion={(filterValue, suggestion) => true}
              optionText={(choice) =>
                choice && choice.id ? choice.name : 'None'
              }
            />
          </ReferenceInput>
          <ReferenceArrayInput
					label='Editable Organizations'
					source='organizations_ids'
					reference='organizations'
					perPage={1000}
					filterToQuery={(searchText) => ({
						q: searchText,
						_q_op: 'isw',
						_autocomplete: true
					})}
					sort={{ field: 'id', order: 'ASC' }}
					fullWidth
				>
					<AutocompleteArrayInput
						matchSuggestion={(filterValue, suggestion) => true}
						optionText='name'
					/>
				</ReferenceArrayInput>
				</SimpleForm>
			</Create>
		</>
	);
}