import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const ServiceCategoryFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='ic_name' label='Name' />
		<ReferenceArrayInput
			label='Major Service Categories'
			source='$majorCategories->id$'
			reference='major_service_categories'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'id', order: 'ASC' }}
		>
			<AutocompleteArrayInput
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceArrayInput>
	</FilterContainer>
);

export default ServiceCategoryFilters;