import React from 'react';
import { DateInput } from 'react-admin';
import Filter from 'components/Filter/Filter';
import { DebouncedTextField, RangeFilter, SearchField } from 'components/Fields';

const FilterContainer = ({ children, ...props }) => {
	return (
		<Filter {...props} qOp='ic'>
			<SearchField source='q' alwaysOn />
			<DebouncedTextField label='ID' source='id' />
			{children}
			<RangeFilter source='created_at' label='Created At'>
				<DateInput source='min_created_at' label='Created At (from)' variant='standard' />
				<DateInput source='max_created_at' label='Created At (to)' variant='standard' />
			</RangeFilter>

			<RangeFilter source='updated_at' label='Updated At'>
				<DateInput source='min_updated_at' label='Updated At (from)' variant='standard' />
				<DateInput source='max_updated_at' label='Updated At (to)' variant='standard' />
			</RangeFilter>
		</Filter>
	);
};

export default FilterContainer;