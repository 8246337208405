import MajorServiceCategoriesIcon from '@mui/icons-material/Subject';

import { MajorServiceCategoriesEdit } from './edit';
import { MajorServiceCategoriesList } from './list';
import { MajorServiceCategoriesShow } from './show';
import { MajorServiceCategoriesCreate } from './create';

export default {
    name: 'major_service_categories',
    options: { label: 'Major Categories', menuParent: '_services' },
    icon: MajorServiceCategoriesIcon,
    list: MajorServiceCategoriesList,
    show: MajorServiceCategoriesShow,
    edit: MajorServiceCategoriesEdit,
    create: MajorServiceCategoriesCreate
};