import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';

export const CategoriesEdit = (props) => {
	return (
		<>
			<Edit title={<EditTitle source='name' />} {...props}>
				<SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
						<TextInput source='name' validate={required()} fullWidth />
						<TextInput source='description' label='Description' multiline fullWidth />
				</SimpleForm>
			</Edit>
		</>
	);
}
