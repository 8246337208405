import config from "../config";

const authProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${config.API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password: password,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
        "withCredentials":true
      }),
      credentials:"include"
    });
    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return response.json();
  },
  verify: async ({ code, email, nonce }) => {
    const request = new Request(`${config.API_URL}/auth/verify`, {
      method: "POST",
      body: JSON.stringify({ code, email, nonce }),
      headers: new Headers({
        "Content-Type": "application/json",
        "withCredentials":true
      }),
      credentials:"include"
    });

    const response = await fetch(request);
    
    if (response.status !== 200) {
      throw new Error('Invalid or expired OTP');
    }
    localStorage.setItem('isLoggedIn', true);

    return response.json();
  },
  logout: async () => {
    const request = new Request(`${config.API_URL}/auth/logout`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "withCredentials": true
      }),
      credentials: "include"
    })

    await fetch(request);
    localStorage.removeItem('isLoggedIn');
    return Promise.resolve();
    
  },
  checkAuth: () => {
    return localStorage.getItem('isLoggedIn') ? Promise.resolve() : Promise.reject();
  },
  checkError: async (error) => {
    if(error.status === 401){
      const request = new Request(`${config.API_URL}/auth/refresh_token`, { 
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "withCredentials": true
        }),
        credentials: "include"
      })

      const response = await fetch(request);
      if (!response.ok) {
        return Promise.reject(); //If it returns a rejected promise, react-admin calls the authProvider.logout() method immediately, and asks the user to log in again.
      }
    }
    return Promise.resolve()
  },
  getPermissions: (params) => Promise.resolve()
};

export default authProvider;
