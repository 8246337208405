import { Resource } from 'react-admin';
import SystemIcon from '@material-ui/icons/Settings';

import adminUserViews from './Account';
import settingViews from './Settings';
import tagGroupsViews from './TagGroup';
import rolesViews from './Role';
import permissionsViews from './Permission';

const SystemRoutes = [
	<Resource
		name='_system'
		icon={SystemIcon}
		options={{ label: 'System', isMenuParent: true }}
	/>,
	<Resource {...adminUserViews} />,
	<Resource {...settingViews} />,
	<Resource {...rolesViews} />,
	<Resource {...permissionsViews} />,
	<Resource {...tagGroupsViews} />
];
export default SystemRoutes;
