import React from 'react';
import { 
	List, 
	TextField, 
	ChipField, 
	FunctionField, 
	ReferenceField 
} from 'react-admin';
import UserFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const UsersList = (props) => (
	<List
		filters={<UserFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage />}
		actions={<ListActions listExporterType='Users' />}
	>
		<DataGridList>
			<FunctionField
				label='Name'
				source='name'
				sortBy='first_name'
				render={(record) =>  {
					const { organizationName } = record.kyc_details;

					return (
						<>
							<div>{record.first_name} {record.last_name}</div>
							{organizationName ? <div style={{fontSize: '10px'}}>({organizationName})</div> : ''}
						</>
					);
				}}
			/>
			<TextField source='email' label='Email' />
			<ChipField source='status' label='Status' variant="outlined" />
			<ReferenceField label='Type' source='type_id' reference='user_types'>
				<TextField source='name' />
			</ReferenceField>
		</DataGridList>
	</List>
);