import React from 'react';
import {
  	BooleanInput
} from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const UserTypeFilters = (props) => (
  	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField label='Name' source='isw_name' />
		<DebouncedTextField label='Description' source='ic_description' />
		<BooleanInput source='requires_mfa' label='MFA?' />
		<BooleanInput source='requires_kyc' label='KYC?' />
  	</FilterContainer>
);

export default UserTypeFilters;
