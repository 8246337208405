import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExportButtonIcon from "@material-ui/icons/GetApp";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import CSVIcon from "@material-ui/icons/Subject";
import jsonExport from "jsonexport/dist";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { HelperListExporter } from "ListExporters/helper";
import pluralize from "pluralize";
import React, { cloneElement, useState } from "react";
import {
	CreateButton,
	downloadCSV,
	ExportButton,
	sanitizeListRestProps,
	TopToolbar,
	useListContext,
} from "react-admin";

const ListActions = (props) => {
	const {
		className,
		exporter,
		listExporterType,
		filters,
		maxResults,
		filterByType,
		hideCreateButton,
		...rest
	} = props;

	const {
		currentSort,
		resource,
		displayedFilters,
		filterValues,
		basePath,
		showFilter,
		total,
	} = useListContext();

	const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState(null);
	let dynamicExportFileType;
	let finalExporter;
	let finalExporterData;

	const pdfHtmlResouceCustomize = (resource) => {
		return resource.replaceAll("/", "_");
	};

	const handleClick = (event) => {
		setExportButtonAnchorEl(event.currentTarget);
	};

  	const handleClose = (exportFileType) => {
		setExportButtonAnchorEl(null);

		switch (exportFileType) {
			case "CSV":
				dynamicExportFileType = "CSV";
				break;

			case "PDF":
				dynamicExportFileType = "PDF";
				break;

			default:
				break;
		}
	};

	if (listExporterType) {
		finalExporter = async (records, fetchRelatedRecords) => {
			switch (listExporterType) {
				default:
					finalExporterData = await HelperListExporter(
						records,
						fetchRelatedRecords,
						pluralize(listExporterType)
					);
			}

			switch (dynamicExportFileType) {
				case "CSV":
					jsonExport(
						finalExporterData.data,
						{
							headers: finalExporterData.headers,
						},
						(err, csv) => {
							downloadCSV(csv, finalExporterData.fileName);
						}
					);
					break;

				case "PDF":
					var doc = new jsPDF("l", "pt");
					doc.autoTable({
						theme: "grid",
						html: `#${pdfHtmlResouceCustomize(resource)}-table`,
						styles: {
							minCellWidth: 40,
						},
						willDrawCell: (data) => {
							if (data.cell.text[0] && data.cell.text[0].includes("₪")) {
								data.cell.text[0] = data.cell.text[0].replace("₪", " ILS ");
							}
							return !(
								data.cell.text[0] === "Edit" ||
								data.cell.text[0] === "Transaction" ||
								data.cell.text[0] === "CLONE" ||
								data.cell.text[0] === "VIEW" ||
								(
									data.cell.section === "head" && 
									data.cell.text[0] === ""
								) ||
								(
									data.cell.section === "body" &&
									data.cell.text[0] === "" &&
									data.column.raw.dataKey === 0
								) ||
								(
									data.cell.section === "body" &&
									data.cell.text[0] === "" &&
									data.column.raw.dataKey === 1
								)
							);
						},
					});
					
					doc.save(`${finalExporterData.fileName}.pdf`);
					
					break;
				
				default:
					break;
			}
		};

		dynamicExportFileType = "";
	} else {
		finalExporter = exporter;
	}

  	return (
    	<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			{
				filters &&
				cloneElement(filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button",
				})
			}
			
			{hideCreateButton ? "" : <CreateButton basePath={basePath} />}

			<>
				<Button
					aria-controls="simple-menu"
					aria-haspopup="true"
					size="small"
					onClick={handleClick}
					startIcon={<ExportButtonIcon />} 
				>
					EXPORT
				</Button>

				<Menu
					id="simple-menu"
					anchorEl={exportButtonAnchorEl}
					keepMounted
					open={Boolean(exportButtonAnchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleClose("CSV")}>
						<ExportButton
							disabled={total === 0}
							resource={resource}
							sort={currentSort}
							filterValues={filterValues}
							exporter={finalExporter}
							label="CSV"
							icon={<CSVIcon />}
							style={{ backgroundColor: "transparent" }}
						/>
					</MenuItem>
				
					<MenuItem onClick={() => handleClose("PDF")}>
						<ExportButton
							disabled={total === 0}
							resource={resource}
							sort={currentSort}
							filterValues={filterValues}
							exporter={finalExporter}
							label="PDF"
							icon={<PDFIcon />}
							style={{ backgroundColor: "transparent" }}
						/>
					</MenuItem>
				</Menu>
			</>
    	</TopToolbar>
  	);
};

export default ListActions;