import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import { Edit, SimpleForm, TextInput, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

export const ServiceCategoriesEdit = (props) => {
	return (
		<>
			<Edit title={<EditTitle source='name' />} {...props}>
				<SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
						<TextInput source='name' validate={required()} fullWidth />
						<ReferenceArrayInput
							label='Major Service Categories'
							source='major_categories_ids'
							reference='major_service_categories'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>
				</SimpleForm>
			</Edit>
		</>
	);
}
