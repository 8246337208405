import React from 'react';
import {
	Edit,
	TabbedForm,
	FormTab,
	TextInput,
	SelectArrayInput,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	AutocompleteInput,
	FormDataConsumer,
	required
  } from 'react-admin';
	import { ColorInput } from 'components/Fields/Inputs/ColorInput';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import CustomTextInputWithTooltip from './components/TextInputWithToolTip/index';

export const TagGroupsEdit = (props) => (
	<Edit title='Edit Tag Group' {...props}>
		<TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar hidedelete />}>
			<FormTab label='General Details'>
				<TextInput source='name' label='Group Name' validate={required()} fullWidth />
				<SelectArrayInput
					validate={required()}
					source='taggable_types'
					label='Taggables'
					choices={[
						{ id: 'Event', name: 'Event (Any)' },
						{ id: 'Incident', name: 'Incident' },
						{ id: 'NewsItem', name: 'News Item' },
						{ id: 'Insight', name: 'Insight' },
						{ id: 'Organization', name: 'Organization' },
						{ id: 'Collaboration', name: 'Collaboration' }
					]}
					fullWidth
				/>
			</FormTab>

			<FormTab label='Associated Tags'>
				<ArrayInput label={false} source='tags' >
					<SimpleFormIterator disableReordering disableRemove>
						<FormDataConsumer>
							{({ 
								getSource, 
								formData
							}) =>
								<ReferenceInput
									label='Parent'
									source={getSource('parent_id')}
									reference='tags'
									perPage={1000}
									variant='standard'
									filterToQuery={(searchText) => ({
										q: searchText,
										_q_op: 'isw',
										group_id: formData.id,
										_system_filters: ['group_id'],
										_autocomplete: true
									})}
									fullWidth
								>
									<AutocompleteInput
										resettable
										matchSuggestion={(filterValue, suggestion) => true}
										optionText={(choice) =>
											choice && choice.id ? choice.name : 'None'
										}
									/>
								</ReferenceInput>
							}
						</FormDataConsumer>

            <FormDataConsumer>
              {({ getSource, scopedFormData }) => (
                <CustomTextInputWithTooltip
                  source={getSource('name')}
                  label='Name'
                  validate={required()}
                  disabled={scopedFormData?.is_editable === false}
                />
              )}
            </FormDataConsumer>

						<ColorInput source='color' label='Color' fullWidth />
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
		</TabbedForm>
	</Edit>
);
