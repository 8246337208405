import React from 'react';
import { 
	ChipField, 
	List, 
	TextField, 
	FunctionField 
} from 'react-admin';

import OrganizationFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const OrganizationsList = (props) => (
	<List
		filters={<OrganizationFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage />}
		actions={<ListActions />}
	>
		<DataGridList>
			<TextField source='name' label='Name' />
			<FunctionField
				label='Status'
				render={(record) => 
					<ChipField 
						record={record} 
						source='status' 
						variant='outlined'
						style={{
							color: record.status === 'inactive' ? '#B38819' : (record.status === 'active' ? '#149D00' : '#BD3E16'),
							borderColor: record.status === 'inactive' ? '#B38819' : (record.status === 'active' ? '#149D00' : '#BD3E16'),
							backgroundColor: record.status === 'inactive' ? '#FDF5D9' : (record.status === 'active' ? '#DCF2D9' : '#F8E0D9')
						}}
					/>
				}
			/>
		</DataGridList>
	</List>
);