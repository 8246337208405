import crypto from 'crypto';
import config from '../config';
import imageCompression from 'browser-image-compression';

export const uploadFile = async (uploadable, file, resource) => {
  if (file) {
    const { rawFile } = file;

    if (rawFile) {
      let fileToUpload = rawFile;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      
      // Check if the file is an image based on MIME type and compress if true
      if (validImageTypes.includes(rawFile.type)) {
        const options = {
          maxSizeMB: 1, 
          maxWidthOrHeight: 1920, 
          useWebWorker: true,
        };

        try {
          fileToUpload = await imageCompression(rawFile, options);
          console.log("Image compressed successfully.");
        } catch (error) {
          console.error("Error during image compression:", error);
        }
      }

      const extension = fileToUpload.name.split(".").pop();

      const presignedUrlRequest = new Request(
        `${config.API_URL}/resources/${resource}/presigned_urls`,
        {
          method: "POST",
          body: JSON.stringify({
            filename: `${uploadable}.${extension}`,
          }),
          headers: new Headers({
            'content-type': 'application/json',
            'withCredentials': true
          }),
          credentials:"include"
        }
      );
      const presignedUrlResponse = await fetch(presignedUrlRequest);
      const presignedUrlResponseJson = await presignedUrlResponse.json();
      const { key, url } = presignedUrlResponseJson.data;

      const s3UploadRequest = new Request(url, {
        method: "PUT",
        body: fileToUpload, 
      });

      const s3UploadResponse = await fetch(s3UploadRequest); // eslint-disable-line

      return key;
    } else {
      return file.name;
    }
  }

  return "";
};

export const uploadLargeFile = async (uploadable, file, resource) => {
  const CHUNK_SIZE = 50_000_000;
  let byteIndex = 0;
  let chunks = [];
  let chunk_bytes = [];
  if (file) {
    const { rawFile,key } = file;
    if (rawFile) {
      const CHUNK_AMOUNT = Math.ceil(rawFile.size / CHUNK_SIZE);
      // for (let i = 0; i < CHUNK_AMOUNT; i += 1) {
      //   let byteEnd = Math.ceil((rawFile.size / CHUNK_AMOUNT) * (i + 1));
      //   // if (i !== CHUNK_AMOUNT - 1) {
      //   const blob = rawFile.slice(byteIndex, byteEnd);
      //   chunks.push(blob);
      //   chunk_bytes.push(
      //     `bytes ${byteIndex}-${byteIndex + (byteEnd - 1 - byteIndex)}/${
      //       rawFile.size
      //     }`
      //   );
      //   byteIndex += byteEnd - byteIndex;
      // }
      const extension = rawFile.name.split(".").pop();
      const presignedUrlRequest = new Request(
        `${config.API_URL}/resources/${resource}/presigned_urls`,
        {
          method: "POST",
          body: JSON.stringify({
            filename: `${uploadable}.${extension}`,
          }),
          headers: new Headers({
            "Content-Type": "application/json",
            "withCredentials":true
          }),
          credentials:"include"
        }
      );
      const presignedUrlResponse = await fetch(presignedUrlRequest);
      const presignedUrlResponseJson = await presignedUrlResponse.json();
      const { key, url } = presignedUrlResponseJson.data;
      const UNIQE_ID = crypto.randomBytes(20).toString("hex");
      const fetcher = [];
      for (let i = 0; i < CHUNK_AMOUNT; i++) {
        const chunkForm = createChunk(url, rawFile, byteIndex, CHUNK_SIZE);
        const chunkEnd = Math.min(byteIndex + CHUNK_SIZE, rawFile.size);

        fetcher.push(
          fetch(
            new Request(url, {
              method: "PUT",
              body: chunkForm,
              headers: new Headers({
                "Content-Range": `bytes ${byteIndex}-${chunkEnd - 1}/${
                  rawFile.size
                }`,
                "X-Unique-Upload-Id": UNIQE_ID,
              }),
            })
          )
        );
        byteIndex += CHUNK_SIZE;
      }
      return Promise.all(fetcher).then(() => {
        return key;
      });
      // for (let i = 0; i < chunks.length; i++) {
      //   let formData = new FormData();
      //   formData.append("file", chunks[i]);

      //   const s3UploadRequest = new Request(url, {
      //     method: "PUT",
      //     body: formData,
      //     headers: new Headers({
      //       "Content-Range": chunk_bytes[i],
      //       "X-Unique-Upload-Id": crypto.randomBytes(20).toString("hex"),
      //     }),
      //   });
      //   const s3UploadResponse = await fetch(s3UploadRequest); // eslint-disable-line
      // }

      return key;
    } else {
      return key;
    }
  }

  return "";
};

const createChunk = (url, file, start, chunk_size, uniqe_id) => {
  const chunkEnd = Math.min(start + chunk_size, file.size);
  const chunk = file.slice(start, chunkEnd);
  const chunkForm = new FormData();
  chunkForm.append("file", chunk);
  return chunkForm;
  // await uploadChunk(url, chunkForm, start, chunkEnd, file, uniqe_id);
};
const uploadChunk = async (url, chunkForm, start, chunkEnd, file, uniqe_id) => {
  const blobEnd = chunkEnd - 1;
  const s3UploadRequest = new Request(url, {
    method: "PUT",
    body: chunkForm,
    headers: new Headers({
      "Content-Range": `bytes ${start}-${blobEnd}/${file.size}`,
      "X-Unique-Upload-Id": uniqe_id,
    }),
  });
  await fetch(s3UploadRequest);
};
