import React from 'react';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import { 
	Edit, 
	TabbedForm,
	TextInput, 
	FormTab,
	SelectInput, 
	ReferenceInput,
	AutocompleteInput,
	FormDataConsumer,
	ArrayInput,
	SimpleFormIterator,
	email, 
	required, 
	ReferenceArrayInput,
	AutocompleteArrayInput
} from 'react-admin';

export const UsersEdit = (props) =>
	<Edit title='Edit User' {...props}>
		<TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
			<FormTab label='Personal Details'>
				<TextInput
					source='first_name'
					label='First Name'
					validate={required()}
					fullWidth
				/>
				<TextInput
					source='last_name'
					label='Last Name'
					validate={required()}
					fullWidth
				/>
				<TextInput
					source='email'
					label='Email'
					validate={[email(), required()]}
					disabled
					fullWidth
				/>
				<FormDataConsumer>
					{({ formData, ...rest }) => {
						const statusChoices = [
							{ id: 'active', name: 'Active' },
							{ id: 'suspended', name: 'Suspended' }
						];

						// Only include "Pending" if the current status is "Pending"
						if (formData.status === 'pending') {
							statusChoices.unshift({ id: 'pending', name: 'Pending' });
						}

						return (
							<SelectInput
								source='status'
								label='Status'
								validate={required()}
								choices={statusChoices}
								fullWidth
								{...rest}
							/>
						);
					}}
				</FormDataConsumer>
				<ReferenceInput
					label='Type'
					source='type_id'
					reference='user_types'
					perPage={25}
					filterToQuery={(searchText) => ({
						q: searchText,
						_q_op: 'isw',
						_autocomplete: true
					})}
					sort={{ field: 'name', order: 'ASC' }}
					disabled
					fullWidth
				>
					<AutocompleteInput
						resettable
						matchSuggestion={(filterValue, suggestion) => true}
						optionText={(choice) =>
							choice && choice.id ? choice.name : 'None'
						}
					/>
				</ReferenceInput>

				<ReferenceArrayInput
					label='Editable Organizations'
					source='organizations_ids'
					reference='organizations'
					perPage={1000}
					filterToQuery={(searchText) => ({
						q: searchText,
						_q_op: 'isw',
						_autocomplete: true
					})}
					sort={{ field: 'id', order: 'ASC' }}
					fullWidth
				>
					<AutocompleteArrayInput
						matchSuggestion={(filterValue, suggestion) => true}
						optionText='name'
					/>
				</ReferenceArrayInput>
			</FormTab>

			<FormTab label='Credentials'>
				<ArrayInput label='' source='credentials'>
					<SimpleFormIterator disableRemove disableReordering>
						<TextInput
							label='Email'
							source='sid'
							validate={[email(), required()]}
							fullWidth
						/>
						<TextInput
							label='Password'
							type='password'
							source='secret'
							fullWidth
						/>
						<SelectInput
							label='Status'
							source='status'
							validate={required()}
							fullWidth
							choices={[
								{ id: 'pending', name: 'Pending Confirmation' },
								{ id: 'active', name: 'Active' },
								{ id: 'locked', name: 'Locked' },
								{ id: 'suspended', name: 'Suspended' },
							]}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
		</TabbedForm>
	</Edit>
