import * as React from "react";
import AppBar from "./AppBar";
import { Layout } from "react-admin";
import Menu from "components/Menu/Menu";

const MyLayout = (props) => {
  return <Layout {...props} appBar={AppBar} />;
};

export default MyLayout;
