import config from '../../../config';
import React, { useEffect, useState } from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { ColorInput } from 'components/Fields/Inputs/ColorInput';
import RichTextInput from 'components/Fields/Inputs/RichTextInput';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import { CustomRemoveButton } from '../components/CustomRemoveButton';
import {
  SimpleFormIterator,
  Edit,
  ArrayInput,
  FormDataConsumer,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  useDataProvider,
} from 'react-admin';

export const FieldsOfOperationEdit = (props) => {
  const [availableServices, setAvailableServices] = useState([]);
  
  const dataProvider = useDataProvider();

  // Fetch all services to determine which ones haven't been selected yet
  useEffect(() => {
    dataProvider
      .getList('services', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => {
        setAvailableServices(data);
      });
  }, [dataProvider]);
  
  const confirmHierarchyChange = async (data) => {
    const fieldOfOperationId = data.id;
    const confirmedServices = data.confirmed_services;
  
    // Send PATCH request with confirmed services in the body
    if (confirmedServices?.length) {
      const resource = 'fields_of_operation';
  
      // Build the URL
      const url = `${config.API_URL}/resources/${resource}/${fieldOfOperationId}/services_hierarchy_change_confirm`;
  
      // Prepare the request body
      const body = JSON.stringify({
        services_ids: confirmedServices,
      });
  
      // Send PATCH request
      await fetch(url, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/json',
          withCredentials: true,
        }),
        credentials: 'include',
        body: body,
      });
    }
  };

  return (
    <Edit title={<EditTitle source='name' />} {...props}>
      <TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar hidedelete beforeSaveCallback={confirmHierarchyChange} />}>
        <FormTab label='General Details'>
          <TextInput source='name' validate={required()} fullWidth />
          <RichTextInput source='description' label='Description' />
          <ColorInput source='color' label='Color' validate={required()} />
        </FormTab>

        <FormTab label='Services'>
          <ArrayInput source='services' label='Services'>
            <SimpleFormIterator disableReordering removeButton={<CustomRemoveButton />}>
              <FormDataConsumer>
                {({ getSource, formData, scopedFormData }) => {
                  const selectedServicesIds = formData?.services?.map((service) => service?.id) || [];

                  // Filter out already selected services from available services
                  const filteredServicesIds = availableServices
                    .filter((service) => !selectedServicesIds.includes(service?.id))
                    .map((service) => service?.id);

                  return (
                    <ReferenceInput
                      source={getSource('id')}
                      reference='services'
                      label='Service'
                      validate={required()}
                      perPage={1000}
                      filter={{ id: filteredServicesIds }}
                      filterToQuery={(searchText) => ({
                        q: searchText,
                        _q_op: 'isw',
                        _autocomplete: true,
                      })}
                      variant='standard'
                      fullWidth
                    >
                      <AutocompleteInput resettable optionText='name' />
                    </ReferenceInput>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};