import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const StoryFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='ic_name' label='Name' />
		<DebouncedTextField source='ic_description' label='Description' />
	</FilterContainer>
);

export default StoryFilters;