import LocalizationIcon from "@material-ui/icons/LanguageRounded";

import { Resource } from "react-admin";
import statesViews from './State';

const LocalizationRoutes = [
  <Resource
    name="localization"
    icon={LocalizationIcon}
    options={{ label: "Localization", isMenuParent: true }}
  />,
  <Resource {...statesViews}/>
];
export default LocalizationRoutes;
