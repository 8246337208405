import settingsIcon from '@material-ui/icons/PhonelinkSetup';

import { SettingsList } from './list';
import { SettingsShow } from './show';
import { SettingsEdit } from './edit';
import { SettingsCreate } from './create';


export default {
	name: 'settings',
	options: { label: 'Settings', menuParent: '_system' },
	icon: settingsIcon,
	list: SettingsList,
	show: SettingsShow,
	edit: SettingsEdit,
	create: SettingsCreate
};