import React from 'react';
import {
	Show,
	SimpleShowLayout,
	TextField,
	EmailField,
} from 'react-admin';
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const AccountsShow = (props) => (
	<Show title={<ViewTitle source='email' />} {...props}>
		<SimpleShowLayout>
			<TextField source='id' label='ID' />
			<TextField source='first_name' label='First Name' />
			<TextField source='last_name' label='Last Name' />
			<EmailField source='email' label='Email' />
			<DateFieldShow source='created_at' label='Created At' />
			<DateFieldShow source='updated_at' label='Updated At' />
		</SimpleShowLayout>
	</Show>
);