import config from '../../../../config';
import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Warning';
import { useSimpleFormIteratorItem, useDataProvider } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Box,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  affectedList: {
    marginTop: theme.spacing(2),
  },
  accordionSummary: {
    backgroundColor: theme.palette.action.hover,
  },
  collaboratorName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  descriptionText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const CustomRemoveButton = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [affectedData, setAffectedData] = useState([]);
  const [namesMap, setNamesMap] = useState({
    services: {},
    fieldsOfOperation: {},
  });
  const { remove, index } = useSimpleFormIteratorItem();
  const { values } = useFormState();
  const form = useForm();
  const dataProvider = useDataProvider();

  const services = values.services || [];

  const fetchAffectedFieldOfOperationServicesHierarchyChange = async (resource, id, service_id) => {
    const url = new URL(`${config.API_URL}/resources/${resource}/${id}/services_hierarchy_change_effects`);
    url.searchParams.append('service_id', service_id);

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        withCredentials: true,
      }),
      credentials: 'include',
    });
    const { data } = await response.json();
    return data;
  };

  const handleClick = async (event) => {
    event.preventDefault();

    const resource = 'fields_of_operation';
    const fieldOfOperationId = values.id;

    // Get the service_id of the service being removed
    const serviceId = services[index]?.id;

    if (serviceId) {
      // Fetch the data related to the removal of the specific service
      const data = await fetchAffectedFieldOfOperationServicesHierarchyChange(resource, fieldOfOperationId, serviceId);

      setAffectedData(data);
      setOpen(true);
    } else {
      remove();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    remove(); // This will call the remove function provided by SimpleFormIterator

    const serviceId = services[index].id;

    // Accumulate the confirmed services in the form state
    const confirmedServices = values.confirmed_services || [];
    form.change('confirmed_services', [...confirmedServices, serviceId]);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // Fetch names when affectedData changes
  useEffect(() => {
    const fetchNames = async () => {
      try {
        // Collect all unique service_ids and field_of_operation_ids
        const serviceIds = new Set();
        const fieldOfOperationIds = new Set();

        affectedData.forEach((orgData) => {
          orgData.services?.forEach((service) => {
            serviceIds.add(service.id);
          });
          orgData.collaborations?.forEach((collab) => {
            collab.breakdown?.forEach((bd) => {
              serviceIds.add(bd.service_id);
              fieldOfOperationIds.add(bd.field_of_operation_id);
            });
          });
        });

        // Fetch services
        const servicesResponse = await dataProvider.getMany('services', {
          ids: Array.from(serviceIds),
        });

        // Fetch fields of operation
        const fieldsResponse = await dataProvider.getMany('fields_of_operation', {
          ids: Array.from(fieldOfOperationIds),
        });

        // Create maps from ID to name
        const servicesMap = {};
        servicesResponse.data.forEach((service) => {
          servicesMap[service.id] = service.name;
        });

        const fieldsMap = {};
        fieldsResponse.data.forEach((field) => {
          fieldsMap[field.id] = field.name;
        });

        setNamesMap({
          services: servicesMap,
          fieldsOfOperation: fieldsMap,
        });
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    };

    if (affectedData.length > 0) {
      fetchNames();
    }
  }, [affectedData, dataProvider]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <WarningIcon className={classes.warningIcon} fontSize="large" />
          <Typography variant="h6" color="error">
            Warning
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Alert severity="warning">
            Removing this service will affect the following organizations and collaborations:
          </Alert>

          {/* Display affected organizations, services, and collaborations */}
          {affectedData.length > 0 ? (
            <div className={classes.affectedList}>
              {affectedData.map((orgData, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.accordionSummary}
                  >
                    <Typography variant="subtitle1">
                      <strong>Organization:</strong> {orgData.organization.name || 'Unnamed Organization'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box width="100%">
                      {/* Affected Services */}
                      {orgData.services && orgData.services.length > 0 && (
                        <>
                          <Typography variant="subtitle2" color="textSecondary">
                            Affected Services:
                          </Typography>
                          <List dense>
                            {orgData.services.map((service, serviceIdx) => (
                              <ListItem key={serviceIdx}>
                                <ListItemText
                                  primary={namesMap.services[service.id] || service.name || 'Unnamed Service'}
                                  secondary={
                                    <Typography variant="body2" color="textSecondary">
                                      This service will be removed from the services hierarchy of the organization for the specified field of operation.
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </>
                      )}

                      {/* Affected Collaborations */}
                      {orgData.collaborations && orgData.collaborations.length > 0 && (
                        <>
                          <Typography variant="subtitle2" color="textSecondary">
                            Affected Collaborations:
                          </Typography>
                          {orgData.collaborations.map((collab, collabIdx) => (
                            <Accordion key={collabIdx} elevation={0}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="body1" className={classes.collaboratorName}>
                                  Collaborator: {collab.collaborator.name || 'Unnamed Collaborator'}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <List dense>
                                  {collab.breakdown.map((bd, bdIdx) => (
                                    <ListItem key={bdIdx} alignItems="flex-start">
                                      <ListItemText
                                        primary={
                                          <Typography variant="body2">
                                            <strong>Service:</strong> {namesMap.services[bd.service_id] || bd.service_id},{' '}
                                            <strong>Field of Operation:</strong>{' '}
                                            {namesMap.fieldsOfOperation[bd.field_of_operation_id] || bd.field_of_operation_id}
                                          </Typography>
                                        }
                                        secondary={
                                          <>
                                            {bd.description && (
                                              <Typography
                                                variant="body2"
                                                className={classes.descriptionText}
                                              >
                                                <strong>Description:</strong>{' '}
                                                <span
                                                  dangerouslySetInnerHTML={{ __html: bd.description }}
                                                />
                                              </Typography>
                                            )}
                                            <Typography variant="body2" color="error">
                                              This collaboration will be removed. Collaborations with this field
                                              of operation and service will no longer be established in the system
                                              unless the service is reattached.
                                            </Typography>
                                          </>
                                        }
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ) : (
            <Typography variant="body2">No affected data.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="default" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary" variant="contained">
            Confirm Removal
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
