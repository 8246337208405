import React from 'react';
import EmptyPage from 'components/EmptyPage';
import { List, TextField } from 'react-admin';
import ServiceCategoryFilters from './filter';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const ServiceCategoriesList = (props) => (
	<List
		filters={<ServiceCategoryFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions listExporterType='Service Categories' />}
	>
		<DataGridList>
			<TextField source='name' label='Name' />
		</DataGridList>
	</List>
);