import tagGroupsIcon from '@material-ui/icons/MoreRounded';

import { TagGroupsList } from './list';
import { TagGroupsShow } from './show';
import { TagGroupsEdit } from './edit';
import { TagGroupsCreate } from './create';


export default {
	name: 'tag_groups',
	options: { label: 'Tags', menuParent: '_system' },
	icon: tagGroupsIcon,
	list: TagGroupsList,
	show: TagGroupsShow,
	edit: TagGroupsEdit,
	create: TagGroupsCreate
};