import React, { useContext } from "react";
import { LoginForm } from "./LoginForm";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext } from "../../themes/ThemeContext";
import { useLocation, useHistory } from "react-router-dom";
import TogetheringLogo from "../../assets/images/Logo/logo.png";
import {
  useAuthProvider,
  useNotify,
  useSafeSetState,
  Login,
} from "react-admin";
import { QrCode } from "./QrCode";

const LiveExpertsLogin = (props) => {
  const { theme, setTheme } = useContext(ThemeContext); // eslint-disable-line

  const pageStyles = makeStyles({
    main: {
      background: "none",
      backgroundColor: "#f1f1f1",
    },
    card: {
      padding: "30px",
      backgroundImage: `url(${TogetheringLogo})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "50px",
      backgroundPosition: "center 40px",
    },
    avatar: {
      margin: "3em",
    },
    icon: {
      display: "none",
    },
  });
  const { redirectTo } = props;
  const location = useLocation();
  const locationState = location.state;

  const history = useHistory();
  const nextPathName = locationState && locationState.nextPathname;

  const redirectUrl = redirectTo ? redirectTo : nextPathName || "/";

  const [step, setStep] = useSafeSetState("login");
  const [loading, setLoading] = useSafeSetState(false);
  const [qrImage, setQrImage] = useSafeSetState("");
  const [nonce, setNonce] = useSafeSetState("");
  const [email, setEmail] = useSafeSetState("");

  const notify = useNotify();
  const authProvider = useAuthProvider();

  const pageClasses = pageStyles();
  const pageProps = {
    ...props,
    classes: pageClasses,
  };

  const handleLogin = (values) => {
    setLoading(true);
    authProvider
      .login(values)
      .then((data) => {
        setQrImage(data.data.qrCode)
        setEmail(values.username)
        setLoading(false);
        setStep("verify");
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  const handleVerification = (values) => {
    setLoading(true);
    authProvider
      .verify(values)
      .then((data) => {
        setLoading(false);
        history.push(redirectUrl);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  const handleQrVerification = (values) => {
    setLoading(true);
    authProvider
      .verify(values)
      .then((data) => {
        setLoading(false);
        history.push(redirectUrl);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  return (
    <Login {...pageProps}>
      {step === "login" ? (
        <LoginForm onSubmit={handleLogin} loading={loading} />
      ) : (
        <QrCode onSubmit={handleQrVerification} loading={loading} qrImage={qrImage} email={email} nonce={nonce}/>
      )}
    </Login>
  );
};

export default LiveExpertsLogin;

