import React from 'react';
import { List, TextField } from 'react-admin';

import DomainFilters from './filter';
import EmptyPage from 'components/EmptyPage';
import DataGridList from 'components/containers/List';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const DomainsList = (props) => (
	<List
		filters={<DomainFilters/>}
		pagination={<Paginator />}
		sort={{ field: 'id', order: 'DESC' }}
		perPage={15}
		{...props}
		empty={<EmptyPage create />}
		actions={<ListActions listExporterType='Domains' />}
	>
		<DataGridList>
			<TextField source='name' label='Name' />
		</DataGridList>
	</List>
);