import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';
import { AutocompleteArrayInput, ReferenceArrayInput, required, SelectInput } from 'react-admin'
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const UserFilters = (props) => (
	<FilterContainer {...props} variant='standard' margin='normal'>
		<DebouncedTextField source='isw_first_name' label='First Name' />
		<DebouncedTextField source='isw_last_name' label='Last Name' />
		<DebouncedTextField source='isw_email' label='Email' />
		<SelectInput
			source='status'
			label='Status'
			validate={required()}
			choices={[
				{ id: 'active', name: 'Active' },
				{ id: 'pending', name: 'Pending' },
				{ id: 'suspended', name: 'Suspended' },
			]}
			fullWidth
		/>
		<ReferenceInput
			label='Type'
			source='type_id'
			reference='user_types'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'name', order: 'ASC' }}
		>
			<AutocompleteInput
				resettable
				matchSuggestion={(filterValue, suggestion) => true}
				optionText={(choice) =>
					choice && choice.id ? choice.name : 'None'
				}
			/>
		</ReferenceInput>
		<ReferenceArrayInput
			label='Organizations'
			source='$organizations->id$'
			reference='organizations'
			perPage={25}
			filterToQuery={(searchText) => ({
				q: searchText,
				_q_op: 'isw',
				_autocomplete: true
			})}
			sort={{ field: 'id', order: 'ASC' }}
		>
			<AutocompleteArrayInput
				matchSuggestion={(filterValue, suggestion) => true}
				optionText='name'
			/>
		</ReferenceArrayInput>
	</FilterContainer>
);

export default UserFilters;