import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { Create, SimpleForm, TextInput, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

export const ServiceCategoriesCreate = (props) => {
	return (
		<>
			<Create {...props} title='Create Service Category'>
				<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
						<TextInput source='name' validate={required()} fullWidth />
						<ReferenceArrayInput
							label='Major Service Categories'
							source='major_categories_ids'
							reference='major_service_categories'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'id', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteArrayInput
								matchSuggestion={(filterValue, suggestion) => true}
								optionText='name'
							/>
						</ReferenceArrayInput>
				</SimpleForm>
			</Create>
		</>
	);
}