import React from 'react';
import { DateFieldShow } from 'components/Fields';
import { ColorField } from 'components/Fields/ColorField';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, TextField, RichTextField, ChipField, TabbedShowLayout, ArrayField, 
				Datagrid, ReferenceField, Tab} from 'react-admin';

export const FieldsOfOperationShow = (props) => (
	<Show title={<ViewTitle source='name' />} {...props}>
		<TabbedShowLayout>
			<Tab label='General Details'>
				<TextField source='id' label='ID' />
				<TextField source='name' label='Name' />
				<RichTextField source='description' label='Description' />
				<ColorField source='color' label='Color' />
				<DateFieldShow source='created_at' label='Created At' />
				<DateFieldShow source='updated_at' label='Updated At' />
			</Tab>

			<Tab label='Services'>
				<ArrayField label="Services" source="services" fullWidth>
					<Datagrid>
						<TextField source="id" />
						<ReferenceField source='id' reference='services' label='Service'>
							<ChipField source='name' variant='outlined' />
						</ReferenceField>
						<ReferenceField source='category_id' reference='service_categories' label='Service Category'>
							<ChipField source='name' variant='outlined' />
						</ReferenceField>
					</Datagrid>
				</ArrayField>
			</Tab>
		</TabbedShowLayout>
	</Show>
)