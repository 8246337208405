import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import { Edit, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput, BooleanInput } from 'react-admin';

export const ServicesEdit = (props) => {
	return (
		<>
			<Edit title={<EditTitle source='name' />} {...props}>
				<SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar hidedelete />}>
						<TextInput source='name' validate={required()} fullWidth />
						<ReferenceInput
							label='Service Category'
							source='category_id'
							reference='service_categories'
							perPage={1000}
							filterToQuery={(searchText) => ({
								q: searchText,
								_q_op: 'isw',
								_autocomplete: true
							})}
							sort={{ field: 'name', order: 'ASC' }}
							fullWidth
						>
							<AutocompleteInput
								resettable
								matchSuggestion={(filterValue, suggestion) => true}
								optionText={(choice) =>
									choice && choice.id ? `${choice.name}` : 'None'
								}
							/>
						</ReferenceInput>
						<BooleanInput source='is_global' label='Global?' fullWidth />
				</SimpleForm>
			</Edit>
		</>
	);
}
