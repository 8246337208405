import AccountsIcon from '@material-ui/icons/VpnKeyRounded';

import { AccountsEdit } from './edit';
import { AccountsList } from './list';
import { AccountsShow } from './show';
import { AccountsCreate } from './create';

export default {
	name: 'admin_users',
	options: { label: 'Accounts', menuParent: '_system' },
	icon: AccountsIcon,
	list: AccountsList,
	show: AccountsShow,
	edit: AccountsEdit,
	create: AccountsCreate
};