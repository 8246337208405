import React from 'react';
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	SelectArrayInput,
	ArrayInput,
	SimpleFormIterator,
	ReferenceInput,
	AutocompleteInput,
	FormDataConsumer,
	required
} from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const TagGroupsCreate = (props) => (
	<Create {...props} title='Create Tag Group'>
		<TabbedForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
			<FormTab label='General Details'>
				<TextInput source='name' label='Group Name' validate={required()} fullWidth />
				<SelectArrayInput
					validate={required()}
					source='taggable_types'
					label='Taggables'
					choices={[
						{ id: 'Event', name: 'Event (Any)' },
						{ id: 'Incident', name: 'Incident' },
						{ id: 'NewsItem', name: 'News Item' },
						{ id: 'Insight', name: 'Insight' },
						{ id: 'Organization', name: 'Organization' },
						{ id: 'Collaboration', name: 'Collaboration' }
					]}
					fullWidth
				/>
			</FormTab>

			<FormTab label='Associated Tags'>
				<ArrayInput label={false} source='tags' >
					<SimpleFormIterator disableReordering>
						<FormDataConsumer>
							{({ 
								getSource, 
								formData
							}) =>
								<ReferenceInput
									label='Parent'
									source={getSource('parent_id')}
									reference='tags'
									perPage={1000}
									variant='standard'
									filterToQuery={(searchText) => ({
										q: searchText,
										_q_op: 'isw',
										group_id: formData.id,
										_system_filters: ['group_id'],
										_autocomplete: true
									})}
									fullWidth
								>
									<AutocompleteInput
										resettable
										matchSuggestion={(filterValue, suggestion) => true}
										optionText={(choice) =>
											choice && choice.id ? choice.name : 'None'
										}
									/>
								</ReferenceInput>
							}
						</FormDataConsumer>

						<TextInput source='name' label='Name' validate={required()} fullWidth />
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
		</TabbedForm>
	</Create>
);