import React from "react";
import ReviewIcon from '@material-ui/icons/RateReview';
import AdminUserIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import SettingIcon from "@material-ui/icons/TuneRounded";
import FaqIcon from "@material-ui/icons/QuestionAnswer";
import LeadCollectionIcon from "@material-ui/icons/ContactMail";


export const AutoCompleteResources = [
	{ icon: <ReviewIcon />, label: "Reviews" },
	{ icon: <AdminUserIcon />, label: "Admin Users" },
	{ icon: <SettingIcon />, label: "Settings" },
	{ icon: <FaqIcon />, label: "Faqs" },
	{ icon: <LeadCollectionIcon />, label: "Leads" },
];
