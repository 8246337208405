import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';

export const StatesCreate = (props) => {
	return (
		<>
			<Create {...props} title='Create State'>
				<SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
					<TextInput source='iso' label='ISO' validate={required()} fullWidth />
            		<TextInput source='name' validate={required()} fullWidth />
				</SimpleForm>
			</Create>
		</>
	);
}